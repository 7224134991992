import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { actionCreator } from "./duck";
import { IState } from "../reducers/interfaces";
import { Nav, INavLink, INavLinkGroup, INavStyles } from "@fluentui/react/lib/Nav";
import { Icon } from "@fluentui/react/lib/Icon";
import { Link } from "react-router-dom";
import { getLeftNavLinkGroups } from "./LeftNav.helper";
import classNames from "./LeftNav.module.scss";
import { IUserInfo } from "./interfaces";
import { Spinner } from "@fluentui/react";
import { isUserSoxAuditor } from "./App.helper";

const navStyles: Partial<INavStyles> = {
  root: {},
  chevronButton: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 0,
  },
};
export interface ILeftNavStateProps {
  selectedNavLink?: INavLink;
  leftNavCollapsed?: boolean;
  isSmallScreen?: boolean;
  isStaging?: boolean;
  userInfo?: IUserInfo;
}

export interface ILeftNavDispatchProps {
  toggleLeftNav: () => void;
  selectLeftNavLink: (navLink: INavLink) => void;
}

export interface ILeftNavState {
  navGroups: INavLinkGroup[];
}

export const LeftNav = (props: ILeftNavDispatchProps & ILeftNavStateProps) => {
  const { leftNavCollapsed, selectedNavLink, isSmallScreen, isStaging, userInfo, toggleLeftNav, selectLeftNavLink } =
    props;
  const showHomeLink = !(userInfo?.isNonGlobalEnvironment || isUserSoxAuditor(userInfo));

  const navGroups = useMemo(() => getLeftNavLinkGroups(isStaging, userInfo), [isStaging, userInfo]);

  useEffect(() => {
    if (!showHomeLink) {
      if (navGroups?.length && navGroups[0].links?.length && window.location.hash === "#/") {
        window.location.href = navGroups[0].links[0].url;
      }
    }
  }, [showHomeLink, navGroups]);

  const onHomeLinkClick = () => {
    isSmallScreen && toggleLeftNav && toggleLeftNav();
  };

  const onLinkClick = (ev, item: INavLink) => {
    selectLeftNavLink(item);
  };

  const title = (leftNavCollapsed ? "Expand" : "Collapse") + " Left Pane";

  const menuPaneClassNames = [classNames.menuPane];

  !selectedNavLink && menuPaneClassNames.push(classNames.homeLinkSelected);

  if (!userInfo) {
    return <Spinner className={classNames.spinner} />;
  } else if (userInfo.error) {
    return <div className={classNames.error}>{userInfo.error}</div>;
  }

  return (
    <div className={`LeftNav_root ${classNames.root}`}>
      <div className={menuPaneClassNames.join(" ")}>
        <div
          className={classNames.menuButtonPane}
          tabIndex={0}
          onClick={toggleLeftNav}
          onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && toggleLeftNav()}
        >
          {!isSmallScreen && <Icon iconName="CollapseMenu" title={title} className={classNames.menuButton} />}{" "}
        </div>

        {showHomeLink && (
          <Link
            className={`LeftNav_homeLink ${classNames.homeLink}`}
            to="/"
            tabIndex={-1}
            onClick={onHomeLinkClick}
            onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onHomeLinkClick()}
          >
            <div className={classNames.homeLinkPane} tabIndex={0}>
              Home
            </div>
          </Link>
        )}
      </div>
      {!leftNavCollapsed && (
        <Nav
          groups={navGroups}
          className={`LeftNav_nav ${classNames.nav}`}
          styles={navStyles}
          onLinkClick={onLinkClick}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IState): ILeftNavStateProps => ({
  selectedNavLink: state.app.selected_left_nav_link,
  leftNavCollapsed: state.app.left_nav_collapsed,
  isSmallScreen: state.app.is_small_screen,
  isStaging: state.app.is_staging,
  userInfo: state.app.login_user_info,
});

const mapDispatchToProps: ILeftNavDispatchProps = {
  toggleLeftNav: actionCreator.toggleLeftNav,
  selectLeftNavLink: actionCreator.selectLeftNavLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
