import React, { useState, useCallback, useMemo } from "react";
import Diagram, { LineMarkerType } from "../../../shared/components/Diagram";
import classNames from "./ControlSdkDesigner.module.scss";
import {
  addNewAction,
  addNewOutput,
  deleteAction,
  deleteOutput,
  getActionNodesAndLinks,
  getInputNodes,
  getOutputNodes,
} from "./ControlSdkDesigner.helper";
import { Panel, DefaultButton } from "@fluentui/react";
import ControlSdkInputEditor from "./ControlSdkInputEditor";
import ControlSdkActionEditor from "./ControlSdkActionEditor";
import ControlSdkOutputEditor from "./ControlSdkOutputEditor";
import ControlSdkRuntimeEditor from "./ControlSdkRuntimeEditor";
import ConfirmModal from "../../common/ConfirmModal";

const defaultDiagramMinHeight = 40;

export enum PanelContentType {
  InputSettings,
  ActionSettings,
  OutputSettings,
  RuntimeSettings,
}

export interface IControlSdkDesignerProps {
  control: any;
  controlSdk: any;
  editConfigItem: (configValue: string) => void;
}

export const ControlSdkDesigner = (props: IControlSdkDesignerProps): JSX.Element => {
  const { control, controlSdk, editConfigItem } = props;
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [panelContentType, setPanelContentType] = useState<PanelContentType>(undefined);
  const [panelTitle, setPanelTitle] = useState<string>();
  const [selectedOutputIndex, setSelectedOutputIndex] = useState<number>();
  const [selectedActionIndex, setSelectedActionIndex] = useState<number>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>();

  const onEditInputsClick = useCallback(() => {
    setPanelTitle("Edit Input Settings");
    setPanelContentType(PanelContentType.InputSettings);
    setShowPanel(true);
  }, []);

  const onAddActionClick = useCallback(() => {
    let actionIndex = addNewAction(control);
    editConfigItem(JSON.stringify(control, null, 2));
    setSelectedActionIndex(actionIndex);
    setPanelTitle("Add New Action");
    setPanelContentType(PanelContentType.ActionSettings);
    setShowPanel(true);
  }, [control, editConfigItem]);

  const onAddOutputClick = useCallback(() => {
    let outputIndex = addNewOutput(control);
    editConfigItem(JSON.stringify(control, null, 2));
    setSelectedOutputIndex(outputIndex);
    setPanelTitle("Add New Output");
    setPanelContentType(PanelContentType.OutputSettings);
    setShowPanel(true);
  }, [control, editConfigItem]);

  const onEditRuntimeClick = useCallback(() => {
    setPanelTitle("Edit Runtime Settings");
    setPanelContentType(PanelContentType.RuntimeSettings);
    setShowPanel(true);
  }, []);

  const onPanelDismiss = useCallback(() => setShowPanel(false), []);

  const onItemDelete = useCallback(() => {
    setShowDeleteConfirmModal(true);
  }, []);

  const onItemDeleteConfirm = useCallback(() => {
    if (panelContentType === PanelContentType.ActionSettings) {
      deleteAction(control, selectedActionIndex);
      editConfigItem(JSON.stringify(control, null, 2));
      setSelectedActionIndex(undefined);
    } else if (panelContentType === PanelContentType.OutputSettings) {
      deleteOutput(control, selectedOutputIndex);
      editConfigItem(JSON.stringify(control, null, 2));
      setSelectedOutputIndex(undefined);
    }
    setShowDeleteConfirmModal(false);
    setShowPanel(false);
  }, [panelContentType, control, selectedActionIndex, selectedOutputIndex, editConfigItem]);

  const onRenderPanelFooterContent = useCallback(
    () => (
      <div className={classNames.buttons}>
        {(panelContentType === PanelContentType.ActionSettings ||
          panelContentType === PanelContentType.OutputSettings) && (
          <DefaultButton className={classNames.button} onClick={onItemDelete}>
            Delete
          </DefaultButton>
        )}
        <DefaultButton className={classNames.button} onClick={onPanelDismiss}>
          Done
        </DefaultButton>
      </div>
    ),
    [panelContentType, onItemDelete, onPanelDismiss]
  );

  const onOutputNodeClick = useCallback((outputIndex) => {
    setSelectedOutputIndex(outputIndex);
    setPanelTitle("Edit Output Settings");
    setPanelContentType(PanelContentType.OutputSettings);
    setShowPanel(true);
  }, []);

  const onActionNodeClick = useCallback((actionIndex) => {
    setSelectedActionIndex(actionIndex);
    setPanelTitle("Edit Action Settings");
    setPanelContentType(PanelContentType.ActionSettings);
    setShowPanel(true);
  }, []);

  const inputNamesMap = useMemo(() => {
    let map = {};
    controlSdk?.DataSources?.forEach((dataSource) =>
      dataSource.Sources?.forEach((source) => (map[source.Id] = source.DisplayName))
    );
    return map;
  }, [controlSdk]);

  let inputNodes = getInputNodes(control?.controlSdkSettings, controlSdk, onEditInputsClick);

  let [actionNodes, actionLinks, actionRowCount] = getActionNodesAndLinks(
    control?.controlSdkSettings,
    controlSdk,
    inputNamesMap,
    onActionNodeClick
  );

  let outputNodes = getOutputNodes(control?.controlSdkSettings, controlSdk, onOutputNodeClick);

  return (
    <>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Inputs</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Edit Inputs"
            iconProps={{ iconName: "Edit" }}
            onClick={onEditInputsClick}
          />
        </div>
        <Diagram classNames={{ diagram: classNames.diagram }} diagram={{ nodes: inputNodes }} />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Actions</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Add Action"
            iconProps={{ iconName: "Add" }}
            onClick={onAddActionClick}
          />
        </div>
        <Diagram
          classNames={{ diagram: classNames.diagram }}
          diagram={{
            nodes: actionNodes,
            links: actionLinks,
            height: actionRowCount * (defaultDiagramMinHeight + 20),
            defaultLinkProps: { lineMarkerType: LineMarkerType.arrowEnd },
          }}
        />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Outputs</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Add Output"
            iconProps={{ iconName: "Add" }}
            onClick={onAddOutputClick}
          />
        </div>
        <Diagram classNames={{ diagram: classNames.diagram }} diagram={{ nodes: outputNodes }} />
      </div>
      <div className={classNames.section}>
        <div className={classNames.sectionHeader}>
          <div className={classNames.sectionLabel}>Runtime</div>
          <DefaultButton
            className={classNames.sectionButton}
            text="Edit Runtime"
            iconProps={{ iconName: "Edit" }}
            onClick={onEditRuntimeClick}
          />
        </div>
      </div>
      <Panel
        isOpen={showPanel}
        headerText={panelTitle}
        onDismiss={onPanelDismiss}
        onRenderFooterContent={onRenderPanelFooterContent}
        isFooterAtBottom
      >
        {panelContentType !== undefined &&
          (panelContentType === PanelContentType.InputSettings ? (
            <ControlSdkInputEditor control={control} controlSdk={controlSdk} editConfigItem={editConfigItem} />
          ) : panelContentType === PanelContentType.ActionSettings ? (
            <ControlSdkActionEditor
              control={control}
              controlSdk={controlSdk}
              editConfigItem={editConfigItem}
              selectedActionIndex={selectedActionIndex}
            />
          ) : panelContentType === PanelContentType.OutputSettings ? (
            <ControlSdkOutputEditor
              control={control}
              controlSdk={controlSdk}
              editConfigItem={editConfigItem}
              selectedOutputIndex={selectedOutputIndex}
            />
          ) : panelContentType === PanelContentType.RuntimeSettings ? (
            <ControlSdkRuntimeEditor control={control} controlSdk={controlSdk} editConfigItem={editConfigItem} />
          ) : (
            <div>Unsupported Designer Panel Content Type</div>
          ))}
      </Panel>
      {showDeleteConfirmModal && (
        <ConfirmModal
          content={`Are you sure you want to delete this item?`}
          onCommit={onItemDeleteConfirm}
          onCancel={() => setShowDeleteConfirmModal(false)}
        />
      )}
    </>
  );
};

export default ControlSdkDesigner;
