import { IField, FieldType, IDataContext } from "../../../shared/components/DataForm";
import { IControl } from "../../controls/interfaces";
import { controlType, onboardingStatus } from "../../controls/HealthReport.helper";
import {
  getTilesFields,
  getColumnFields,
  getDisplayTypeOptions,
  getTileDataSourceFields,
  makeOption,
} from "../common/helper";
import {
  EntityValidationType,
  TimeComponent,
  IIcMConnector,
  ThresholdType,
  IConfigItem,
} from "../../common/interfaces";
import { IServiceConfig } from "../../common/serviceContentInterfaces";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { IDropdownOption } from "@fluentui/react";

const defaultFieldWidth = 280;

export const actionOutputPrefix = "actionOutput";

export const defaultControl: IControl = {
  id: "new",
  name: "",
  type: "Recon",
  owningTeam: "",
  onboardingStatus: 0,
  soxCompliance: true,
  outputConfig: {
    entityIds: "",
    lineItemColumns: [],
  },
};

export const getEntityTypeOptions = (entityTypes: string[]) => {
  let options = [];
  entityTypes &&
    entityTypes.forEach((entityType) => {
      options.push({ key: entityType, text: entityType });
    });

  return options;
};

export const getValidationTypeOptions = () => {
  let validationTypes = [];

  for (var validationType in EntityValidationType) {
    if (isNaN(Number(validationType))) {
      validationTypes.push({ key: validationType, text: validationType });
    }
  }

  return validationTypes;
};

export const getValidationNameOptions = (
  configServices: IServiceConfig[],
  entityType: string,
  validationType: string
) => {
  let validationNames = [];
  if (entityType && validationType) {
    let serviceConfig = configServices?.find((config) => config.name === entityType);
    if (serviceConfig?.validationTests?.length) {
      let validationTests = serviceConfig.validationTests.filter((test) => test.validationType === validationType);
      validationTests.forEach((validationTest) => {
        if (validationTest.validations?.length) {
          validationTest.validations.forEach((validation) => {
            let validationName = validation.name ?? validation.targetAttribute ?? validation.attribute;
            validationNames.push({ key: validationName, text: validationName });
          });
        } else {
          //sla validations
          let validationName = validationTest.sourceEntityType;
          validationNames.push({ key: validationName, text: validationName });
        }
      });
      if (validationTests.length) validationNames.push({ key: "ValidationTimeout", text: "ValidationTimeout" });
    }
  }

  return validationNames;
};

export const getTimeComponentOptions = () => {
  let timeComponents = [];
  for (var time in TimeComponent) {
    if (isNaN(Number(time))) {
      timeComponents.push({ key: time, text: time });
    }
  }

  return timeComponents;
};

export const getThresholdTypeOptions = () => {
  let thresholdTypes = [];
  for (var type in ThresholdType) {
    if (isNaN(Number(type))) {
      thresholdTypes.push({ key: type, text: ThresholdType[type] });
    }
  }

  return thresholdTypes;
};

export const getDocumentationOptions = (complianceConfigs: IConfigItem[]): IDropdownOption[] => {
  let options: IDropdownOption[] = [];
  if (complianceConfigs?.length) {
    complianceConfigs.forEach((complianceDoc) => {
      options = options.concat(makeOption(complianceDoc.id, complianceDoc.name));
    });
  }
  return options;
};

export const getActionOutputDisplayName = (controlSdk, actions, actionNumber: number): string => {
  let action = actions && actions[actionNumber - 1],
    actionName = controlSdk?.Actions?.find((sdkAction) => sdkAction.Name === action?.name)?.DisplayName;
  return action?.customName || `Action #${actionNumber}: ${actionName || action?.name || "Output"}`;
};

export const extractSdkColumnNames = (columns: string[], schema, prefix = "") => {
  schema?.forEach &&
    schema?.forEach((schema) => {
      let columnName = prefix + schema.Name;

      if (schema.Type !== "object") {
        if (columns.indexOf(columnName) < 0) {
          columns.push(columnName);
        }
      } else if (schema.Schema?.length) {
        extractSdkColumnNames(columns, schema.Schema, columnName + ".");
      }
    });
};

export const extractSdkColumnsFromInputs = (
  controlSdk,
  actionInputs,
  columns: string[],
  dataSourceTypeName: string
) => {
  const targetInputs = controlSdk?.DataSources?.find(
    (dataSource) => dataSource.Id === dataSourceTypeName
  )?.Sources?.filter((source) => actionInputs.indexOf(source.Id) >= 0);

  if (targetInputs?.length) {
    targetInputs.forEach((input) => extractSdkColumnNames(columns, input.Schema));
  }
};

export const extractSdkColumnsFromInputParameters = (action, columns: string[]) => {
  const actionName = action?.name,
    parameters = action?.parameters;

  if (actionName === "append" && parameters?.appends?.length) {
    parameters.appends.forEach((appendColumn) =>
      appendColumn.columns?.forEach((column) => column.columnName && columns.push(column.columnName))
    );
  } else if (actionName === "mapping" && parameters?.columns?.length) {
    parameters.columns.forEach(
      (mappingColumn) => mappingColumn.propName && mappingColumn.propValue && columns.push(mappingColumn.propValue)
    );
  } else if (actionName === "select" && parameters?.columns?.length) {
    columns.length = 0;
    parameters.columns.forEach((selectColumn) => columns.push(selectColumn));
  } else if (actionName === "aggregate" && parameters?.aggregations?.length) {
    parameters.aggregations.forEach((aggregation) =>
      aggregation.propValue?.forEach((propValue) => propValue.alias && columns.push(propValue.alias))
    );
  }
};

export const getSdkColumns = (
  dataContext: IDataContext,
  controlSdk,
  columns: string[] = null,
  inputKeys = null,
  controlSdkSettings = undefined,
  selectedActionIndex = undefined
): string[] => {
  columns = columns || [];
  const currentActionContext = dataContext?.parentDataContext,
    actionInputs = inputKeys || controlSdkSettings?.input_keys || currentActionContext?.context?.input_keys;

  if (actionInputs?.length) {
    extractSdkColumnsFromInputs(controlSdk, actionInputs, columns, "Entities");
    extractSdkColumnsFromInputs(controlSdk, actionInputs, columns, "Datasets");
    extractSdkColumnsFromInputs(controlSdk, actionInputs, columns, "Dimensions");

    const actions = controlSdkSettings?.actions || currentActionContext?.parentDataContext?.context?.actions,
      actionInputsFromActionOutput = actionInputs.filter((input) => input.startsWith(actionOutputPrefix)),
      hasCurrentInputFromActionOutput = actionInputsFromActionOutput?.length > 0,
      actionIndex = selectedActionIndex !== undefined ? selectedActionIndex : currentActionContext.index;

    // Recursively go up the action chain if the input is from an action output.
    if (hasCurrentInputFromActionOutput && actionIndex >= 0) {
      actionInputsFromActionOutput.forEach((inputKey) => {
        const actionIndex = Number(inputKey.replace(actionOutputPrefix, ""));
        const previousActionIndex = actionIndex - 1;
        const previousAction = actions[previousActionIndex];
        const previousInputs = previousAction.input_keys;

        getSdkColumns(dataContext, controlSdk, columns, previousInputs, controlSdkSettings, previousActionIndex);

        // For certain action types, add new column according to the parameters.
        extractSdkColumnsFromInputParameters(previousAction, columns);
      });
    }
  }

  return columns;
};

export const getSdkParameterOptions = (parameter, columns?) => {
  if (parameter.Options?.length) {
    return parameter.Options;
  }

  let optionValues = parameter.ValidOptions;

  if (columns?.length && parameter.ItemSource === "InputColumns") {
    optionValues = columns;
  }

  return optionValues?.map((option) => ({ key: option, text: option }));
};

export const getInputsOptions = (controlSdk, dataContext: IDataContext) => {
  const inputKeys = dataContext.parentDataContext?.context?.input_keys;
  const actions = dataContext.parentDataContext?.parentDataContext?.context?.actions;

  return inputKeys?.map((inputKey) => {
    if (inputKey.startsWith(actionOutputPrefix)) {
      const actionNumber = Number(inputKey.replace(actionOutputPrefix, ""));
      return { key: inputKey, text: getActionOutputDisplayName(controlSdk, actions, actionNumber) };
    } else {
      if (controlSdk?.DataSources?.length) {
        for (let i = 0; i < controlSdk.DataSources.length; i++) {
          let dataSource = controlSdk.DataSources[i];
          for (let j = 0; j < dataSource.Sources.length; j++) {
            let source = dataSource.Sources[j];
            if (inputKey === source.Id) {
              return { key: inputKey, text: source.DisplayName };
            }
          }
        }
      }
    }
    return { key: inputKey, text: inputKey };
  });
};

export const getSdkParameterObjectArrayField = (
  controlSdk,
  dataContext: IDataContext,
  parameter,
  columns?: string[]
) => {
  return {
    fieldName: parameter.Name,
    fieldType: FieldType.items,
    label: parameter.DisplayName,
    itemTypeName: parameter.DisplayName === "Columns" ? "Column" : parameter.DisplayName,
    collapsed: false,
    collapsible: true,
    hideItemsSearch: true,
    hints: parameter.Description,
    fields: getSdkParameterFields(controlSdk, dataContext, parameter.Schema?.Schema, columns),
  };
};

export const getSdkParameterPropertyObjectField = (
  controlSdk,
  dataContext: IDataContext,
  parameter,
  columns?: string[]
) => {
  let propName = parameter.Schema.PropertyName,
    propValue = parameter.Schema.PropertyValue;

  propName.Name = "propName";
  propValue.Name = "propValue";

  return {
    fieldName: parameter.Name,
    fieldType: FieldType.items,
    label: parameter.DisplayName,
    itemTypeName: parameter.DisplayName === "Columns" ? "Column" : parameter.DisplayName,
    collapsed: false,
    collapsible: true,
    hideItemsSearch: true,
    hints: parameter.Description,
    fields: [
      getSdkParameterField(controlSdk, dataContext, propName, columns),
      getSdkParameterField(controlSdk, dataContext, propValue, columns),
    ],
  };
};

export const getSdkParameterField = (
  controlSdk,
  dataContext: IDataContext,
  parameter,
  columns,
  context = undefined,
  useDesignerPanel: boolean = false
): IField => {
  const useDropdown = parameter.ItemSource === "InputColumns" || parameter.ItemSource === "Inputs";
  const multiSelect = parameter.Type === "array" && useDropdown;

  if (parameter.Type === "array" && parameter.Schema?.Type === "object") {
    return getSdkParameterObjectArrayField(controlSdk, dataContext, parameter, columns);
  }

  if (parameter.Type === "object" && parameter.Schema?.Type === "property") {
    return getSdkParameterPropertyObjectField(controlSdk, dataContext, parameter, columns);
  }

  if (dataContext?.context && parameter.ItemSource === "Inputs") {
    parameter.Options = getInputsOptions(controlSdk, dataContext);
  }

  return {
    fieldName: parameter.Name,
    fieldType: () => {
      if (useDropdown) {
        return FieldType.enumeration;
      } else if (parameter.Type === "array") {
        return FieldType.textArray;
      } else if (parameter.Type === "bool") {
        return FieldType.boolean;
      } else if (parameter.Type === "int") {
        return FieldType.number;
      } else if (parameter.ValidOptions?.length) {
        return FieldType.enumeration;
      }

      return FieldType.text;
    },
    options: getSdkParameterOptions(parameter, columns),
    multiSelect,
    label: parameter.DisplayName,
    width: (dataContext: IDataContext) => {
      if (useDesignerPanel) {
        return defaultFieldWidth + "px";
      }

      if (parameter.Width) {
        return parameter.Width;
      }

      var width = Number(defaultFieldWidth);
      // For multiselect, adjust the width according to the number of selected items.
      if (multiSelect) {
        width = Math.max(
          width,
          140 * (dataContext?.context?.hasOwnProperty(parameter.Name) ? dataContext.context[parameter.Name]?.length : 0)
        );
      }

      return width + "px";
    },
    defaultValue: parameter.DefaultValue,
    value: context ? context[parameter.Name] : undefined,
    required: parameter.Required,
    hints: parameter.Description,
  };
};

export const getSdkParameterFields = (
  controlSdk,
  dataContext: IDataContext,
  parameters: any[],
  columns?: string[],
  context?: object,
  useDesignerPanel: boolean = false
): IField[] =>
  parameters?.map((parameter) =>
    getSdkParameterField(controlSdk, dataContext, parameter, columns, context, useDesignerPanel)
  );

export const getGeneralFields = (
  control: IControl,
  icmConnectors: IIcMConnector[],
  entityTypes: string[],
  configServices: IServiceConfig[],
  controlMetadata: any,
  controlSdk: any,
  testDataSource?: Function
): IField[] => {
  if (!control) return null;

  let icmConnectorOptions = [{ key: "", text: "" }];

  let weekdayOptions = [
    { key: "su", text: "Sunday" },
    { key: "mo", text: "Monday" },
    { key: "tu", text: "Tuesday" },
    { key: "we", text: "Wednesday" },
    { key: "th", text: "Thursday" },
    { key: "fr", text: "Friday" },
    { key: "sa", text: "Saturday" },
  ];

  icmConnectors.forEach((connector) => {
    icmConnectorOptions.push({ key: connector.id, text: connector.serviceName });
  });

  let icmSettingOptions = [{ key: "", text: "" }];

  control?.icmSettings?.forEach((icmSetting, index) => {
    icmSettingOptions.push({ key: index, text: `IcM Setting #${index + 1}` });
  });

  let financialKpiTypeOptions = [{ key: "", text: "" }],
    selectedFinancialKpiType = undefined;

  controlMetadata?.financialKpiTypes?.forEach((type) => {
    financialKpiTypeOptions.push({ key: type.id, text: type.name });

    if (type.id === control.financialKpiType) {
      selectedFinancialKpiType = type;
    }
  });

  let financialKpiSubTypeOptions = [{ key: "", text: "" }];

  selectedFinancialKpiType?.subTypes?.forEach((subType) => {
    financialKpiSubTypeOptions.push({ key: subType.id, text: subType.name });
  });

  let controlCategoryOptions = [{ key: "", text: "" }];

  controlMetadata?.controlCategories?.forEach((category) => {
    controlCategoryOptions.push({ key: category.id, text: category.name });
  });

  let allowManagedControl =
    control.type === "Change" ||
    control.type === "Custom" ||
    control.type === "Dynamic" ||
    control.type === "EntityMissingSla" ||
    control.type === "EntityValidation" ||
    control.type === "Recon";

  let managedControlDetailFields = [];

  if (control.type === "Change") {
    managedControlDetailFields = [
      ...getTileDataSourceFields(control),
      {
        fieldName: "query",
        label: "Query",
        width: "100%",
        value: control.query,
        allowFullEditor: true,
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
      },
    ];
  } else if (control.type === "Custom") {
    managedControlDetailFields = [
      ...getTileDataSourceFields(control),
      {
        fieldName: "query",
        label: "Failed Line Items Query",
        width: "100%",
        value: control.query,
        allowFullEditor: true,
        hints:
          "Specific the query on returning the list of failed control line items.  If no item is returned, a 'Pass' (otherwise a 'Fail') control result will be generated.",
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
      },
    ];
  } else if (control.type === "EntityMissingSla") {
    managedControlDetailFields = [
      {
        fieldName: "monitorSlaBufferTimespan",
        label: "SLA Buffer Timespan",
        value: control.monitorSlaBufferTimespan,
        width: "140px",
        valueWidth: "50px",
        regEx: "^[0-9]+[dh]$",
        hints:
          "The timespan beyond the SLA expected time of which the target entity is considered missing, e.g. 5d for 5 days.  By default, 7 times the monitor frequency is used.",
      },
      {
        fieldName: "financialEntityType",
        fieldType: FieldType.enumeration,
        label: "Financial Entity Type",
        options: getEntityTypeOptions(entityTypes),
        value: control.financialEntityType,
        width: "300px",
      },
    ];
  } else if (control.type === "EntityValidation") {
    managedControlDetailFields = [
      {
        fieldName: "timeComponent",
        fieldType: FieldType.enumeration,
        label: "Time Component",
        options: getTimeComponentOptions(),
        value: control.timeComponent,
        width: "120px",
        hints: "Specify time component. default is FYTD.",
      },
      {
        fieldName: "monitorThreshold",
        fieldType: FieldType.number,
        label: "Threshold",
        value: control.monitorThreshold,
        width: "120px",
        valueWidth: "120px",
      },
      {
        fieldName: "thresholdType",
        fieldType: FieldType.enumeration,
        label: "Threshold Type",
        options: getThresholdTypeOptions(),
        value: control.thresholdType,
        width: "150px",
        valueWidth: "150px",
        lastInRow: true,
        hints: "Specify threshold type. default is failure count.",
      },
      {
        fieldName: "financialEntityType",
        fieldType: FieldType.enumeration,
        label: "Financial Entity Type",
        options: getEntityTypeOptions(entityTypes),
        value: control.financialEntityType,
        width: "300px",
      },
      {
        fieldName: "validationType",
        fieldType: FieldType.enumeration,
        label: "Validation Type",
        options: getValidationTypeOptions(),
        value: control.validationType,
        width: "200px",
      },
      {
        fieldName: "validationName",
        fieldType: FieldType.enumeration,
        label: "Validation Name",
        options: getValidationNameOptions(configServices, control.financialEntityType, control.validationType),
        value: control.validationName,
        width: "300px",
      },
    ];
  } else if (control.type === "Recon") {
    managedControlDetailFields = [
      {
        fieldName: "managedDataSources",
        fieldType: FieldType.items,
        label: "Managed Data Sources",
        value: control.managedDataSources,
        collapsed: false,
        itemDisplayView: ItemDisplayView.list,
        fields: [
          ...getTileDataSourceFields(),
          {
            fieldName: "keyFieldName",
            label: "Key Field Name",
            hints:
              "Optionally specify the field/column name of the item key, used for matching the same item in other data sources.  'key' is the default field/column name used.",
          },
          {
            fieldName: "valueFieldNames",
            fieldType: FieldType.textArray,
            label: "Value Field Names",
            lastInRow: true,
            hints:
              "Optionally specify a comma-separated list of field/column names of the item values, used for comparing with item in other data sources with the same key.  'value' is the default field/column name used.",
          },
          {
            fieldName: "query",
            label: "Query",
            width: "100%",
            allowFullEditor: true,
          },
          {
            fieldName: "testDataSource",
            fieldType: FieldType.button,
            label: "Test Data Source",
            firstInRow: true,
            visible: !!testDataSource,
            onClick: testDataSource,
          },
        ],
      },
    ];
  } else if (control.type === controlType.Dynamic) {
    managedControlDetailFields = [
      {
        fieldType: FieldType.container,
        label: "Precursor Data Source",
        collapsed: true,
        collapsible: true,
        compact: true,
        fields: [
          {
            fieldType: FieldType.context,
            fieldName: "precursorDataSource",
            fields: [
              ...getTileDataSourceFields(control?.precursorDataSource || {}),
              {
                fieldName: "precursorQuery",
                label: "Precursor Query",
                width: "100%",
                allowFullEditor: true,
                hints:
                  "Specific the precursor query on returning values that can be used as placeholders for the Result KPI Query or the Line Items Query",
              },
              {
                fieldName: "testDataSource",
                fieldType: FieldType.button,
                label: "Test Data Source",
                firstInRow: true,
                visible: !!testDataSource,
                onClick: testDataSource,
                fieldProps: { queryFieldName: "precursorQuery" },
              },
            ],
          },
        ],
      },
      ...getTileDataSourceFields(control),
      {
        fieldName: "statusFieldName",
        label: "Status Field Name",
        hints:
          "Optionally specify the field/column name of the KPI status and Line Items status, 'status' is the default field/column name used.",
      },
      {
        fieldName: "resultKPIQuery",
        label: "Result KPI Query",
        width: "100%",
        value: control.resultKPIQuery,
        allowFullEditor: true,
        hints:
          "Specific the query on returning the one row result KPI which should include a 'status' field(string type) value as 'pass' or 'fail'.",
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
        fieldProps: { queryFieldName: "resultKPIQuery" },
      },
      {
        fieldName: "lineItemQuery",
        label: "Line Items Query",
        width: "100%",
        value: control.lineItemQuery,
        allowFullEditor: true,
        hints:
          "Specific the query on returning the list of control line items which should includ a 'status' field(string type) value as 'pass' or 'fail'.",
      },
      {
        fieldName: "testDataSource",
        fieldType: FieldType.button,
        label: "Test Data Source",
        firstInRow: true,
        visible: !!testDataSource,
        onClick: testDataSource,
        fieldProps: { queryFieldName: "lineItemQuery" },
      },
    ];
  }

  return [
    {
      fieldName: "id",
      fieldType: FieldType.displayOnly,
      label: "Control ID",
      value: control.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "isRestricted",
      label: "Is Restricted",
      fieldType: FieldType.boolean,
      hints: "Indicates if this control requires a viewer to have a full MS Sales WW access",
    },
    {
      fieldName: "offline",
      label: "Offline",
      fieldType: FieldType.boolean,
      hints: "Indicate if this control should be offlined from showing control result in control view.",
    },
    {
      fieldName: "offlineReason",
      label: "Offline Reason",
      valueWidth: "300px",
      hints: "Reason for offline",
      visible: "!!this.offline",
    },
    {
      fieldName: "isMEClose",
      label: "Is ME Close",
      fieldType: FieldType.boolean,
      hints: "Indicate if this control is included for ME (Month End) Close.",
    },
    {
      fieldName: "freshnessInterval",
      label: "Freshness Interval (Days)",
      fieldType: FieldType.number,
      valueWidth: "40px",
      maxValue: 40,
      minValue: 0,
      visible: control.type !== controlType.External,
      hints:
        "Expected control result update frequency(days) for Control Freshness check.\nIf the control did not get update within the specified days, an IcM incident will be created.\nThe default is 2 days, enter zero to bypass this freshness check.",
    },
    {
      fieldName: "freshnessSkip",
      label: "Freshness Skip",
      fieldType: FieldType.enumeration,
      options: weekdayOptions,
      value: control.freshnessSkip,
      multiSelect: true,
      valueWidth: Math.max(140, 80 * (control.freshnessSkip?.length || 0)) + "px",
      visible: control.type !== controlType.External,
      hints: "Define which weekday(s) to skip for freshness calculation.",
    },
    {
      fieldName: "freshnessIcmSetting",
      label: "Freshness IcM Setting",
      fieldType: FieldType.enumeration,
      options: icmSettingOptions,
      value: control.freshnessIcmSetting,
      valueWidth: "140px",
      visible: control.type !== controlType.External,
      hints: "Define which IcM Setting to be used for freshness incidents.",
    },
    {
      fieldName: "type",
      fieldType: FieldType.enumeration,
      label: "Control Type",
      value: control.type,
      options: Object.keys(controlType).map((key) => ({ key: key, text: controlType[key] })),
      width: "160px",
      firstInRow: true,
    },
    {
      fieldName: "name",
      label: "Control Name",
      value: control.name,
      width: "60%",
      required: true,
    },
    {
      fieldName: "shortName",
      label: "Short Name",
      value: control.shortName,
      fillWidth: true,
      lastInRow: true,
    },
    {
      fieldName: "description",
      label: "Description",
      value: control.description,
      width: "100%",
    },
    {
      fieldName: "onboardingStatus",
      fieldType: FieldType.enumeration,
      label: "Onboarding Status",
      value: Number(control.onboardingStatus),
      options: onboardingStatus.map((status, index) => ({ key: index, text: status })),
      width: "200px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: control.owner,
      width: "120px",
      required: true,
      hints: "Alias of control owner",
    },
    {
      fieldName: "owningTeam",
      label: "Owning Team",
      value: control.owningTeam,
      width: "120px",
      hints: "Alias of control owning team",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: control.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this control",
    },
    {
      fieldName: "externalUrl",
      label: "External URL",
      value: control.externalUrl,
      width: "100%",
      visible: control.type === controlType.External,
      hints: "Url link to the external control info page",
    },
    {
      fieldName: "showLatestResultOnly",
      label: "Show Latest Result Only",
      fieldType: FieldType.boolean,
      value: control.showLatestResultOnly,
      visible: control.type !== controlType.External && control.type !== controlType.SDK,
      hints: "Show only the latest control result of the same data end date",
    },
    {
      fieldName: "maxLineItemsToView",
      label: "Max Line Items",
      fieldType: FieldType.number,
      value: control.maxLineItemsToView,
      valueWidth: "100px",
      visible: control.type !== controlType.External && control.type !== controlType.SDK,
      hints: "Maximum line items to be shown in the line item list view, default is 10000",
    },
    {
      fieldName: "maxLinkedItemCount",
      label: "Max Linked Items",
      fieldType: FieldType.number,
      value: control.maxLinkedItemCount,
      valueWidth: "100px",
      visible: control.type !== controlType.External && control.type !== controlType.SDK,
      hints: "Maximum linked items to be shown on the control details page, default is 200",
    },
    {
      fieldName: "financialKpiType",
      fieldType: FieldType.enumeration,
      label: "Financial KPI Type",
      options: financialKpiTypeOptions,
      width: "260px",
      firstInRow: true,
      hints: "Select the related Financial KPI Type",
    },
    {
      fieldName: "financialKpiSubType",
      fieldType: FieldType.enumeration,
      label: "Financial KPI Sub Type",
      options: financialKpiSubTypeOptions,
      width: "260px",
      visible: !!control.financialKpiType,
      hints: "Select the related Financial KPI Sub Type",
    },
    {
      fieldName: "controlCategory",
      fieldType: FieldType.enumeration,
      label: "Control Category",
      options: controlCategoryOptions,
      width: "260px",
      hints: "Select the related Control Category",
    },
    {
      fieldName: "isManaged",
      label: "Is Managed Control",
      fieldType: FieldType.boolean,
      value: control.isManaged,
      visible: allowManagedControl,
      firstInRow: true,
      hints: "Allow Commerce Radar to manage and automatically monitor this control",
    },
    {
      fieldName: "controlSdkSettings",
      fieldType: FieldType.context,
      visible: control.type === controlType.SDK,
      fields: [
        {
          label: "Inputs",
          fieldType: FieldType.container,
          collapsible: true,
          collapsed: false,
          fields: [
            ...getControlSdkInputFields(control, controlSdk),
            {
              fieldName: "actions",
              fieldType: FieldType.items,
              label: "Actions",
              itemTypeName: "Action",
              collapsible: true,
              collapsed: false,
              hideItemsSearch: true,
              hints: "Specify one or more actions for Transform",
              customTabHeader: (dataContext, itemTypeName, index) => {
                const contextAction = dataContext.context?.actions[index];
                const sdkAction = controlSdk?.Actions?.find((action) => action.Name === contextAction.name);
                return contextAction?.customName || `${itemTypeName} #${index + 1}: ${sdkAction?.DisplayName || ""}`;
              },
              fields: getControlSdkActionFields(controlSdk),
            },
            {
              fieldName: "Outputs",
              fieldType: FieldType.items,
              label: "Outputs",
              itemTypeName: "Output",
              collapsible: true,
              collapsed: false,
              hideItemsSearch: true,
              hints: "Specify one or more outputs",
              customTabHeader: (dataContext, itemTypeName, index) => {
                var sdkOutput = controlSdk?.Outputs?.find(
                  (output) => output.Id === dataContext.context?.Outputs[index].Id
                );
                return `${itemTypeName} #${index + 1}: ${sdkOutput?.DisplayName || ""}`;
              },
              fields: getControlSdkOutputFields(controlSdk, control?.controlSdkSettings),
            },
            {
              fieldName: "Runtime",
              fieldType: FieldType.context,
              fields: [
                {
                  label: "Runtime",
                  fieldType: FieldType.container,
                  collapsible: true,
                  collapsed: false,
                  fields: getControlSdkRuntimeFields(control, controlSdk),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      fieldName: "managedControlSettings",
      label: "Managed Control Settings",
      fieldType: FieldType.container,
      collapsible: true,
      collapsed: false,
      visible: allowManagedControl && !!control.isManaged,
      fields: [
        {
          fieldName: "monitorFrequency",
          label: "Frequency",
          value: control.monitorFrequency,
          width: "80px",
          valueWidth: "50px",
          regEx: "^[0-9]+[mdh]$",
          hints: "Monitor frequency of the control, e.g. 1h for every hour, 1d for every day, 1m for every month",
        },
        {
          fieldName: "monitorStartTime",
          label: "Start Time",
          value: control.monitorStartTime,
          width: "80px",
          valueWidth: "60px",
          fillWidth: false,
          regEx: "^([0-1][0-9]|[2][0-3]):([0-5][0-9])$",
          lastInRow: true,
          hints: "Monitor start time of the control in PST military time, e.g. 08:00, 15:30",
        },
        ...managedControlDetailFields,
      ],
    },
    {
      fieldName: "icmSettings",
      label: "IcM Settings",
      fieldType: FieldType.items,
      itemTypeName: "IcM Setting",
      collapsible: true,
      collapsed: false,
      visible: control.type !== controlType.External,
      hints: "Setup one or more IcM settings",
      fields: [
        {
          fieldName: "autoIncidentOnFailure",
          fieldType: FieldType.boolean,
          label: "Auto Gen On Failure",
          value: control.autoIncidentOnFailure,
          lastInRow: true,
          hints:
            "Select this if you want our system to automatically generate an IcM incident upon control result status is failed. New IcM will not be created if existing IcM incident for the control is still avtive unless the 'New Incident On Failure' flag is checked.",
        },
        {
          fieldName: "icmConnectorId",
          fieldType: FieldType.enumeration,
          label: "Connector",
          options: icmConnectorOptions,
          value: control.icmConnectorId,
          visible: "!!this.autoIncidentOnFailure",
          width: "200px",
          hints:
            "Selected a supported IcM Connector Service.  If your IcM service is not listed here, please contact crhelp for onboarding info.",
        },
        {
          fieldName: "icmTeamPublicId",
          label: "Team Public ID",
          value: control.icmTeamPublicId,
          visible: "!!this.autoIncidentOnFailure",
          width: "300px",
          hints:
            "Specify the IcM Team Public ID, usually in form of TenantName/TeamName, e.g. COMMERCERADAR\\CommerceDecisionSupportSystem. Go to the IcM portal > Administration > Manage Teams to find out the Team Public ID for your specific team.",
        },
        {
          fieldName: "icmRoutingId",
          label: "Routing Id",
          value: control.icmRoutingId,
          visible: "!!this.autoIncidentOnFailure",
          width: "100px",
          hints: "Specify IcM Routing Rule Id as needed.",
        },
        {
          fieldName: "icmEnvironment",
          label: "Environment",
          value: control.icmEnvironment,
          visible: "!!this.autoIncidentOnFailure",
          width: "100px",
          valueWidth: "60px",
          hints:
            "Override IcM environment for Radar INT controls.  Radar PROD controls will always default to PROD environment.",
        },
        {
          fieldName: "icmKeywords",
          label: "Keywords",
          value: control.icmKeywords,
          visible: "!!this.autoIncidentOnFailure",
          hints: "Specify IcM Keywords as needed.",
        },
        {
          fieldName: "icmIncidentType",
          fieldType: FieldType.enumeration,
          label: "Incident Type",
          value: control.icmIncidentType,
          options: [
            { key: "", text: "" },
            { key: "Customer Reported", text: "Customer Reported" },
            { key: "Deployment", text: "Deployment" },
            { key: "Live Site", text: "Live Site" },
          ],
          visible: "!!this.autoIncidentOnFailure",
          width: "200px",
          hints: "Specify IcM Incident Type as needed.",
        },
        {
          fieldName: "defaultIncidentSeverity",
          fieldType: FieldType.number,
          label: "Default Severity",
          value: control.defaultIncidentSeverity,
          visible: "!!this.autoIncidentOnFailure",
          regEx: "[01234]",
          width: "120px",
          valueWidth: "50px",
          hints:
            "Specify the default IcM incident severity, a number from 0 to 4. If you are ingesting control result data, you can dynamically set this value by including the field 'icmSeverity' in the ResultDetail.",
        },
        {
          fieldName: "maxIcMFailLineItem",
          fieldType: FieldType.number,
          label: "Max IcM Fail Line Item",
          value: control.maxIcMFailLineItem,
          visible: "!!this.autoIncidentOnFailure",
          width: "150px",
          valueWidth: "50px",
          hints: "Specify the maximum fail line items to be listed in IcM incident, default is 10",
        },
        {
          fieldName: "newIncidentOnFailure",
          fieldType: FieldType.boolean,
          label: "New Incident On Failure",
          value: control.newIncidentOnFailure,
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "Always create a new IcM incident for each failed control result status, regardless if an active IcM incident exists or not.",
        },
        {
          fieldName: "doNotDisplayInUX",
          fieldType: FieldType.boolean,
          label: "Do Not Display In UX",
          value: control.doNotDisplayInUX,
          visible: "!!this.autoIncidentOnFailure",
          hints: "If checked, the generated incidents would not be displayed on the control details page.",
        },
        {
          fieldName: "useNameAsTitle",
          fieldType: FieldType.boolean,
          label: "Use Control Name as Title",
          value: control.useNameAsTitle,
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "Select this if you want to only use the control name as the IcM title without adding any prefix or suffix.",
        },
        {
          fieldName: "icmCustomTitle",
          label: "Custom Title Text",
          value: control.icmCustomTitle,
          visible: "!!this.autoIncidentOnFailure",
          width: "300px",
          hints: "To define a static custom IcM incident title, specify the custom title text here.",
        },
        {
          fieldName: "icmTitleFieldName",
          label: "Custom Title Field Name",
          value: control.icmTitleFieldName,
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "To define the IcM incident title dynamically, specify the field name or JSON path in the control's ResultDetail where such dynamic custom title is defined.",
        },
        {
          fieldName: "failCountFieldName",
          label: "Fail Count Field Name",
          value: control.failCountFieldName,
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "To define the fail count field name in the control's ResultDetail field.  By default, 'failCount' would be used.",
        },
        {
          fieldName: "statusFieldName",
          label: "Status Field Name",
          value: control.statusFieldName,
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "To define the status field name in the control's ResultDetail field.  By default, ResultStatus in the top level control result fields would be used for the first IcM settings, and 'statusN' in the ResultDetail field would be used for subsequent IcM settings where N is the number of the IcM settings. Note: The field name is case-sensitive.",
        },
        {
          fieldName: "icmTsgId",
          label: "TSG ID",
          value: control.icmTsgId,
          visible: "!!this.autoIncidentOnFailure",
          hints: "TSG link to related TSG doc.",
        },
        {
          fieldName: "icmTsgOutput",
          label: "TSG Output",
          value: control.icmTsgOutput,
          visible: "!!this.autoIncidentOnFailure",
          hints: "TSG Output for mitigation/resolution details.",
        },
        {
          fieldName: "icmTags",
          fieldType: FieldType.textArray,
          label: "Tags",
          value: control.icmTags,
          visible: "!!this.autoIncidentOnFailure",
          width: "300px",
          hints: "Comma-separated list of tags.",
        },
        {
          fieldName: "icmSummaryFieldNames",
          fieldType: FieldType.textArray,
          label: "Summary Field Names",
          value: control.icmSummaryFieldNames,
          width: "300px",
          visible: "!!this.autoIncidentOnFailure",
          hints:
            "Comma-separate list to specify the field names or JSON paths in the control's ResultDetail to be displayed in the IcM incident summary field.",
        },
        {
          fieldName: "icmSummaryPrefixContent",
          label: "Summary Prefix Content",
          value: control.icmSummaryPrefixContent,
          allowFullEditor: true,
          width: "300px",
          visible: "!!this.autoIncidentOnFailure",
          hints: "Fixed content to be added to the beginning of the incident summary field. Simple HTML is allowed.",
        },
        {
          fieldName: "icmSummarySuffixContent",
          label: "Summary Suffix Content",
          value: control.icmSummarySuffixContent,
          allowFullEditor: true,
          width: "300px",
          visible: "!!this.autoIncidentOnFailure",
          hints: "Fixed content to be added to the end of the incident summary field. Simple HTML is allowed.",
        },
        {
          fieldName: "customFields",
          label: "IcM Custom Fields",
          fieldType: FieldType.items,
          itemTypeName: "Field",
          itemDisplayView: ItemDisplayView.list,
          collapsed: false,
          visible: "!!this.autoIncidentOnFailure",
          fields: [
            {
              fieldName: "containerType",
              fieldType: FieldType.enumeration,
              label: "Container Type",
              options: [
                { key: "cr", text: "Commerce Radar" },
                { key: "ce", text: "C + E" },
              ],
              width: "150px",
            },
            {
              fieldName: "icmFieldName",
              label: "IcM Field Name",
              width: "150px",
            },
            {
              fieldName: "lineItemFieldName",
              label: "Line Item Detail Field Name",
              width: "180px",
              visible: !!control.createIcMOnLineItemLevel,
              hints: "The field name or JSON path in LineItemDetail for the associated IcM custom field.",
            },
            {
              fieldName: "icmValueFieldName",
              label: "Result Detail Field Name",
              width: "180px",
              visible: !control.createIcMOnLineItemLevel,
              hints: "The field name or JSON path in ResultDetail for the associated IcM custom field.",
            },
            {
              fieldName: "fixedValue",
              label: "Fixed Value",
              width: "180px",
              hints:
                "Specify an optional fixed value for the custom field, 'Line Item Field Name' will be ignored if this value is defined.",
            },
          ],
        },
        {
          fieldName: "createIcMOnLineItemLevel",
          fieldType: FieldType.boolean,
          label: "Create IcM On Line Item Level",
          value: control.createIcMOnLineItemLevel,
          visible: "!!this.autoIncidentOnFailure",
          lastInRow: true,
          hints: "Select this if you want to create IcM On failed line item level instead of Control result level.",
        },
        {
          fieldName: "lineItemIcMSettings",
          label: "Line Item Level IcM Settings",
          fieldType: FieldType.container,
          collapsible: true,
          collapsed: false,
          visible: "!!this.autoIncidentOnFailure && !!this.createIcMOnLineItemLevel",
          fields: [
            {
              fieldName: "lineItemIdFieldName",
              label: "Line Item Id Field Name",
              value: control.lineItemIdFieldName,
              width: "150px",
              hints:
                "The field name or JSON path in LineItemDetail for specifying the unique Id to identify a failed line item.",
            },
            {
              fieldName: "lineItemTitleFieldName",
              label: "Line Item Title Field Name",
              value: control.lineItemTitleFieldName,
              width: "160px",
              hints: "Optional field name or JSON path in LineItemDetail to be appended to IcM incident title.",
            },
            {
              fieldName: "maxIcMIncidents",
              fieldType: FieldType.number,
              label: "Max IcM Incidents",
              value: control.maxIcMIncidents,
              width: "150px",
              valueWidth: "50px",
              lastInRow: true,
              hints:
                "Specify the maximum incidents to be created if fail line items are more than that, default is 100",
            },
          ],
        },
      ],
    },
    {
      label: "Advanced",
      fieldType: FieldType.container,
      collapsible: true,
      visible: control.type !== controlType.External,
      fields: [
        {
          fieldName: "autoLinkIcM",
          fieldType: FieldType.boolean,
          label: "Auto Link IcM On Failure",
          value: control.autoLinkIcM,
          hints:
            "If you don't want our system to create an IcM incident automatically, instead, link the IcM incident specified in ResultDetail to the control when the result status is failed. Default IcM incident Id property name is 'icmId', you may specify different name in the 'IcM Id Field Name' config to the right.",
        },
        {
          fieldName: "icmIdFieldName",
          label: "IcM Id Field Name",
          value: control.icmIdFieldName,
          visible: "!!this.autoLinkIcM",
          width: "120px",
          hints:
            "The field name or JSON path in ResultDetail for specifying the IcM Id to be linked, default is 'icmId'.",
        },
        {
          fieldName: "loadAllItems",
          fieldType: FieldType.boolean,
          label: "Load All Line Items",
          hints: "By default, only failed line items are loaded",
          value: control.loadAllItems || (control.outputConfig && control.outputConfig.loadAllItems),
        },
        {
          fieldName: "locale",
          label: "Default Locale",
          value: control.locale,
          hints: "Use browser locale standard, default to 'en-us'",
          regEx: "^([\\w]{2})-([\\w]{2})$",
          width: "100px",
        },
        {
          fieldName: "localeFieldName",
          label: "Locale Field Name",
          value: control.localeFieldName,
          width: "200px",
        },
        {
          fieldName: "currency",
          label: "Default Currency",
          value: control.currency,
          hints: "Use browser currency standard, default to 'USD'",
          regEx: "^([\\w]{3})$",
          width: "100px",
        },
        {
          fieldName: "currencyFieldName",
          label: "Currency Field Name",
          value: control.currencyFieldName,
          width: "200px",
        },
        {
          fieldName: "decimal",
          fieldType: FieldType.number,
          label: "Default Decimal",
          value: control.decimal,
          width: "100px",
        },
        {
          fieldName: "decimalFieldName",
          label: "Decimal Field Name",
          value: control.decimalFieldName,
          width: "200px",
        },
        {
          fieldName: "cumulativeDeltaFieldName",
          label: "Cumulative Delta Field Name",
          value: control.cumulativeDeltaFieldName,
          width: "200px",
        },
        {
          fieldName: "adoLink",
          label: "ADO Link",
          value: control.adoLink,
          width: "100%",
          hints: "URL link to the corresponding Azure DevOps item.",
        },
      ],
    },
  ];
};

export const getOutputFields = (outputConfig): IField[] => {
  if (!outputConfig) return null;

  return [
    {
      fieldName: "entityIds",
      fieldType: FieldType.textArray,
      label: "Entity IDs",
      value: outputConfig.entityIds,
      width: "100%",
      hints: "Comma separated list of entity IDs",
    },
    {
      fieldName: "sourceId",
      label: "Source ID",
      value: outputConfig.sourceId,
      width: "160px",
    },
    {
      fieldName: "sourceName",
      label: "Source Name",
      value: outputConfig.sourceName,
      width: "160px",
    },
    {
      fieldName: "targetId",
      label: "Target ID",
      value: outputConfig.targetId,
      width: "160px",
      visible: "this.type === 'Recon'",
    },
    {
      fieldName: "targetName",
      label: "Target Name",
      value: outputConfig.targetName,
      width: "160px",
      visible: "this.type === 'Recon'",
    },
    {
      fieldName: "lineItemTableTitle",
      label: "Line Item Table Title",
      value: outputConfig.lineItemTableTitle,
      width: "50%",
      lastInRow: true,
      hints: "Title for the line item table",
    },
    {
      ...getColumnFields({
        fieldName: "lineItemColumns",
        label: "Line Item Columns",
        value: outputConfig.lineItemColumns,
        compact: false,
        hints: "Columns definition for the line item table",
        collapsed: false,
      }),
    },
    {
      fieldName: "resultTiles",
      label: "Result KPI Tiles",
      fieldType: FieldType.items,
      value: outputConfig.resultTiles,
      hints: "Result KPI tiles definition for the control",
      itemTypeName: "KPI Tile",
      collapsed: false,
      fields: [
        {
          fieldName: "name",
          label: "Title",
        },
        {
          fieldName: "fieldName",
          label: "Field Name",
          width: "300px",
          hints:
            "Field name defined in the ResultDetail field of the ControlResult table. You can also default a valid JSON path for the field as well.",
        },
        {
          fieldName: "displayType",
          fieldType: FieldType.enumeration,
          label: "Display Format",
          options: getDisplayTypeOptions(),
          width: "160px",
        },
        {
          fieldName: "color",
          label: "Value Color",
          width: "80px",
        },
        {
          fieldName: "fixedValue",
          label: "Fixed Value",
          hints: "Specify a optional fixed value for the tile.  'Field Name' will be ignored if this field is defined.",
        },
        {
          fieldName: "template",
          label: "Template",
          hints: "Specify a optional template for the tile.",
          allowFullEditor: true,
          width: "100%",
        },
      ],
    },
    { ...getTilesFields(outputConfig.tiles), collapsed: false },
  ];
};

export const getControlSdkInputFields = (control, controlSdk): IField[] => {
  let sdkDataSourceTypeOptions = controlSdk?.DataSources?.filter(
    (dataSource) => dataSource.Id === "Entities" || dataSource.Id === "Datasets"
  )?.map((dataSource) => ({ key: dataSource.Id, text: dataSource.DisplayName }));

  let sdkEntities = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Entities")?.Sources;
  let sdkEntityOptions = sdkEntities?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  let sdkDatasets = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Datasets")?.Sources;
  let sdkDatasetOptions = sdkDatasets?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  let sdkDimensions = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Dimensions")?.Sources;
  let sdkDimensionOptions = sdkDimensions?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  return [
    {
      fieldName: "dataSourceType",
      fieldType: FieldType.enumeration,
      label: "Data Source Type",
      options: sdkDataSourceTypeOptions,
      value: control?.controlSdkSettings?.dataSourceType,
      width: "140px",
    },
    {
      fieldName: "entities",
      fieldType: FieldType.enumeration,
      label: "Entities",
      options: sdkEntityOptions,
      value: control?.controlSdkSettings?.entities,
      multiSelect: true,
      valueWidth: Math.max(300, 120 * (control?.controlSdkSettings?.entities?.length || 0)) + "px",
      visible: control?.controlSdkSettings?.dataSourceType === "Entities",
    },
    {
      fieldName: "datasets",
      fieldType: FieldType.enumeration,
      label: "Datasets",
      options: sdkDatasetOptions,
      value: control?.controlSdkSettings?.datasets,
      multiSelect: true,
      valueWidth: Math.max(300, 120 * (control?.controlSdkSettings?.datasets?.length || 0)) + "px",
      visible: control?.controlSdkSettings?.dataSourceType === "Datasets",
    },
    {
      fieldName: "dimensions",
      fieldType: FieldType.enumeration,
      label: "Dimensions",
      options: sdkDimensionOptions,
      value: control?.controlSdkSettings?.dimensions,
      multiSelect: true,
      valueWidth: Math.max(200, 120 * (control?.controlSdkSettings?.dimensions?.length || 0)) + "px",
    },
  ];
};

const getSdkAction = (controlSdk, dataContext: IDataContext) =>
  controlSdk?.Actions?.find(
    (action) =>
      action.Name === dataContext?.context?.name || action.Name === dataContext?.parentDataContext?.context?.name
  );

export const getControlSdkActionFields = (controlSdk, controlSdkSettings?, selectedActionIndex?): IField[] => {
  let sdkEntities = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Entities")?.Sources;
  let sdkEntityOptions = sdkEntities?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  let sdkDatasets = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Datasets")?.Sources;
  let sdkDatasetOptions = sdkDatasets?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  let sdkDimensions = controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Dimensions")?.Sources;
  let sdkDimensionOptions = sdkDimensions?.map((source) => ({ key: source.Id, text: source.DisplayName }));

  let sdkActionOptions = controlSdk?.Actions?.map((action) => ({ key: action.Name, text: action.DisplayName }));

  return [
    {
      fieldName: "name",
      fieldType: FieldType.enumeration,
      label: "Action",
      options: sdkActionOptions,
      width: defaultFieldWidth + "px",
    },
    {
      fieldName: "customName",
      fieldType: FieldType.text,
      label: "Custom Action Name",
      width: defaultFieldWidth + "px",
    },
    {
      fieldName: "actionDescription",
      fieldType: FieldType.displayOnly,
      label: "Description",
      value: (dataContext: IDataContext) => {
        var sdkAction = getSdkAction(controlSdk, dataContext);
        return sdkAction?.Description;
      },
      visible: (dataContext: IDataContext) => {
        var sdkAction = getSdkAction(controlSdk, dataContext);
        return !!sdkAction?.Description;
      },
    },
    {
      fieldName: "input_keys",
      fieldType: FieldType.enumeration,
      label: "Action Inputs",
      options: (dataContext: IDataContext) => {
        var options = [];
        var baseContext = dataContext?.parentDataContext?.context || controlSdkSettings;

        if (baseContext?.entities?.length) {
          options = options?.concat(
            sdkEntityOptions?.filter((option) => baseContext.entities.indexOf(option.key) >= 0) || []
          );
        }

        if (baseContext?.datasets?.length) {
          options = options?.concat(
            sdkDatasetOptions?.filter((option) => baseContext.datasets.indexOf(option.key) >= 0) || []
          );
        }

        if (baseContext?.dimensions?.length) {
          options = options?.concat(
            sdkDimensionOptions?.filter((option) => baseContext.dimensions.indexOf(option.key) >= 0) || []
          );
        }

        let actionIndex = selectedActionIndex !== undefined ? selectedActionIndex : dataContext?.index;

        if (actionIndex >= 1) {
          for (var i = 0; i < actionIndex; i++) {
            options?.push({
              key: `${actionOutputPrefix}${i + 1}`,
              text: getActionOutputDisplayName(controlSdk, baseContext?.actions, i + 1),
            });
          }
        }

        return options;
      },
      multiSelect: (dataContext: IDataContext) => {
        var sdkAction = getSdkAction(controlSdk, dataContext);

        // For multiselect, adjust the width according to the number of selected items.
        return sdkAction?.RequiredInputCount > 1;
      },
      multiSelectMax: (dataContext: IDataContext) => {
        var sdkAction = getSdkAction(controlSdk, dataContext);
        return sdkAction?.RequiredInputCount || 1;
      },
      saveAsArray: true,
      firstInRow: true,
      width: (dataContext: IDataContext) => {
        if (selectedActionIndex !== undefined) {
          return defaultFieldWidth + "px";
        }

        var width = Number(defaultFieldWidth);
        var sdkAction = getSdkAction(controlSdk, dataContext);

        // For multiselect, adjust the width according to the number of selected items.
        if (sdkAction?.RequiredInputCount > 1) {
          var baseContext = controlSdkSettings || dataContext?.context;
          width = Math.min(1200, Math.max(300, 120 * (baseContext?.input_keys?.length || 0)));
        }

        return width + "px";
      },
    },
    {
      label: "Parameters",
      fieldType: FieldType.container,
      compact: true,
      collapsible: true,
      collapsed: false,
      visible: (dataContext: IDataContext) => {
        var sdkAction = getSdkAction(controlSdk, dataContext);
        return !!sdkAction?.Parameters?.length;
      },
      fields: [
        {
          fieldName: "parameters",
          fieldType: FieldType.context,
          fields: (dataContext: IDataContext) => {
            var sdkAction = getSdkAction(controlSdk, dataContext);
            var columns = getSdkColumns(
              dataContext,
              controlSdk,
              undefined,
              undefined,
              controlSdkSettings,
              selectedActionIndex
            );

            return getSdkParameterFields(
              controlSdk,
              dataContext,
              sdkAction?.Parameters,
              columns,
              undefined,
              selectedActionIndex !== undefined
            );
          },
        },
      ],
    },
  ];
};

const getSdkOutput = (controlSdkSettings, controlSdk, dataContext: IDataContext, selectedOutputIndex?: number) =>
  controlSdk?.Outputs?.find((output) =>
    selectedOutputIndex !== undefined
      ? output.Id === controlSdkSettings?.Outputs[selectedOutputIndex]?.Id
      : output.Id === dataContext.context?.Id
  );

export const getControlSdkOutputFields = (controlSdk, controlSdkSettings, selectedOutputIndex?: number): IField[] => {
  let sdkOutputOptions = controlSdk?.Outputs?.map((output) => ({ key: output.Id, text: output.DisplayName })),
    selectedOutputId = controlSdkSettings?.Outputs[selectedOutputIndex]?.Id;

  return [
    {
      fieldName: "Id",
      fieldType: selectedOutputIndex !== undefined && selectedOutputId ? FieldType.displayOnly : FieldType.enumeration,
      label: "Output",
      options: sdkOutputOptions,
      width: defaultFieldWidth + "px",
    },
    {
      fieldName: "OutputDescription",
      fieldType: FieldType.displayOnly,
      label: "Description",
      value: (dataContext: IDataContext) => {
        var sdkOutput = getSdkOutput(controlSdkSettings, controlSdk, dataContext, selectedOutputIndex);
        return sdkOutput?.Description;
      },
      visible: (dataContext: IDataContext) => {
        var sdkOutput = getSdkOutput(controlSdkSettings, controlSdk, dataContext, selectedOutputIndex);
        return !!sdkOutput?.Description;
      },
    },
    {
      label: "Parameters",
      fieldType: FieldType.container,
      compact: true,
      collapsible: true,
      collapsed: false,
      visible: (dataContext: IDataContext) => {
        var sdkOutput = getSdkOutput(controlSdkSettings, controlSdk, dataContext, selectedOutputIndex);
        return !!sdkOutput?.Parameters?.length;
      },
      fields: [
        {
          fieldName: "Options",
          fieldType: FieldType.context,
          fields: (dataContext: IDataContext) => {
            var sdkOutput = controlSdk?.Outputs?.find((output) =>
              selectedOutputIndex !== undefined
                ? output.Id === selectedOutputId
                : output.Id === dataContext.context?.Id || output.Id === dataContext.parentDataContext.context?.Id
            );

            return getSdkParameterFields(
              controlSdk,
              dataContext,
              sdkOutput?.Parameters,
              undefined,
              controlSdkSettings?.Outputs[selectedOutputIndex]?.Options
            );
          },
        },
      ],
    },
  ];
};

export const getControlSdkRuntimeFields = (control, controlSdk): IField[] =>
  getSdkParameterFields(null, null, controlSdk?.Runtime, undefined, control?.controlSdkSettings?.Runtime);
