import React from "react";
import { authHelper } from "../api/apiBase";
import { Account } from "msal/lib-es6";
import { Callout, ICalloutProps, DirectionalHint } from "@fluentui/react/lib/Callout";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Theme } from "../shared/utilities/themeHelper";
import classNames from "./Callout.module.scss";
import { Toggle } from "@fluentui/react";
import { IUserInfo, copilotFeatureFlagStorageName } from "./interfaces";

export interface IUserCalloutProps extends ICalloutProps {
  user: Account | null;
  userPhoto?: string;
  userInfo?: IUserInfo;
  onDismiss?: () => void;
}

export class UserCallout extends React.Component<IUserCalloutProps> {
  constructor(props: IUserCalloutProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  render() {
    const { user, userPhoto, userInfo, onDismiss } = this.props;
    const copilotFeatureFlag = localStorage[copilotFeatureFlagStorageName];

    return (
      <Callout
        className={classNames.root}
        {...this.props}
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomRightEdge}
        onDismiss={onDismiss}
        setInitialFocus={true}
        tabIndex={0}
      >
        <div className={classNames.mePane}>
          {userPhoto && <img src={userPhoto} alt="User" className={classNames.mePhoto} />}
          <div className={classNames.meInfoPane}>
            <div className={classNames.meName}>{user.name}</div>
            <div className={classNames.meInfo}>{user.userName}</div>
          </div>
        </div>
        <div className={classNames.settingsPane}>
          <h4>User Settings</h4>
          {userInfo && !userInfo.isNonGlobalEnvironment && (
            <Toggle
              label="Copilot"
              onText="On"
              offText="Off"
              defaultChecked={!!copilotFeatureFlag}
              onChange={this.onCopilotToggle}
            />
          )}
          <Dropdown
            defaultSelectedKey={localStorage.theme || Theme.light.toString()}
            label="Theme"
            options={themeOptions}
            tabIndex={0}
            onChange={this.onThemeDropdownChange}
          />
        </div>
        <div className={classNames.linkPane}>
          <div
            className={classNames.link}
            tabIndex={0}
            onClick={authHelper.logout}
            onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && authHelper.logout()}
          >
            Logout
          </div>
        </div>
      </Callout>
    );
  }

  onThemeDropdownChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    localStorage.theme = item.key;
    window.location.reload();
  };

  onCopilotToggle = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
    localStorage[copilotFeatureFlagStorageName] = checked ? "On" : "";
    window.location.reload();
  };
}

export default UserCallout;

const themeOptions: IDropdownOption[] = [
  {
    key: Theme.light.toString(),
    text: "Light",
  },
  {
    key: Theme.dark.toString(),
    text: "Dark",
  },
];
