import React, { useContext, useEffect } from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import HomePage from "../home/HomePage";
import DebugPage from "../home/DebugPage";
import HealthDashboardPage from "../home/HealthDashboardPage";
import HealthReport from "../controls/HealthReport";
import IncidentsReport from "../incidents/IncidentsReport";
import UnderConstructionPage from "../home/UnderConstructionPage";
import MyControlList from "../management/control/MyControlList";
import MyControlEdit from "../management/control/MyControlEdit";
import MyIncidentViewList from "../management/incidentView/MyIncidentViewList";
import MyIncidentViewEdit from "../management/incidentView/MyIncidentViewEdit";
import MyReportGroupList from "../management/reportGroup/MyReportGroupList";
import MyReportGroupEdit from "../management/reportGroup/MyReportGroupEdit";
import MyAnomalyTestList from "../management/anomalyTest/MyAnomalyTestList";
import MyAnomalyTestEdit from "../management/anomalyTest/MyAnomalyTestEdit";
import MyAppSettingList from "../management/appSetting/MyAppSettingList";
import MyAppSettingEdit from "../management/appSetting/MyAppSettingEdit";
import MyCollectorServiceEdit from "../management/collectorService/MyCollectorServiceEdit";
import MyCollectorServiceList from "../management/collectorService/MyCollectorServiceList";
import MyControlViewEdit from "../management/controlView/MyControlViewEdit";
import MyControlViewList from "../management/controlView/MyControlViewList";
import MyEntityEdit from "../management/entity/MyEntityEdit";
import MyEntityList from "../management/entity/MyEntityList";
import MyEntityViewEdit from "../management/entityView/MyEntityViewEdit";
import MyEntityViewList from "../management/entityView/MyEntityViewList";
import MyEntityTraceViewEdit from "../management/entityTraceView/MyEntityTraceViewEdit";
import MyEntityTraceViewList from "../management/entityTraceView/MyEntityTraceViewList";
import MyEventSubscriptionEdit from "../management/eventSubscription/MyEventSubscriptionEdit";
import MyEventSubscriptionList from "../management/eventSubscription/MyEventSubscriptionList";
import MyDevOpsViewEdit from "../management/devOpsView/MyDevOpsViewEdit";
import MyDevOpsViewList from "../management/devOpsView/MyDevOpsViewList";
import MyChecklistEdit from "../management/checklist/MyChecklistEdit";
import MyChecklistList from "../management/checklist/MyChecklistList";
import MyEventEdit from "../management/event/MyEventEdit";
import MyEventList from "../management/event/MyEventList";
import MyServiceContentEdit from "../management/serviceContent/MyServiceContentEdit";
import MyServiceContentList from "../management/serviceContent/MyServiceContentList";
import MyTeamEdit from "../management/team/MyTeamEdit";
import MyTeamList from "../management/team/MyTeamList";
import MyStreamRunEdit from "../management/streamRun/MyStreamRunEdit";
import MyStreamRunList from "../management/streamRun/MyStreamRunlList";
import MyStreamScheduleEdit from "../management/streamSchedule/MyStreamScheduleEdit";
import MyStreamScheduleList from "../management/streamSchedule/MyStreamSchedulelList";
import ReportGroupAppModule, { IReportGroupAppModuleProps } from "./ReportGroupAppModule";
import GraphHome from "../graph/GraphHome";
import DevOpsView from "../works/DevOpsView";
import OkrHome from "../okrs/OkrHome";
import FmeCloseIssuesHome from "../fmeCloseIssues/FmeCloseIssuesHome";
import SearchHome from "../search/SearchHome";
import { leftNavUrls } from "../../app/LeftNav.helper";
import StreamsHome from "../streams/StreamsHome";
import ConfigHistory from "../management/common/ConfigHistory";
import { AppModuleContext } from "./AppModule";
import { AppContext } from "../../app/App";
import { AuditOfAuditsPage } from "../home/AuditOfAuditsPage";
import ReprocessFinancialEntities from "../graph/ReprocessFinancialEntities";
import ServicebusMessageExplorer from "../graph/ServicebusMessageExplorer";

const isProduction =
  window.location.hostname === "radar.microsoft.com" ||
  window.location.hostname === "commerceradar-secondary.azurewebsites.net";

const FeatureRequests = () => <DevOpsView isFeatureRequests />;
const ServicesTax = () => <ReportGroupAppModule reportGroupId="2" leftNavLinkUrl={leftNavUrls.all.services.tax} />;
const ServicesModernFinacials = () => (
  <ReportGroupAppModule reportGroupId="17" leftNavLinkUrl={leftNavUrls.all.services.modernFinancials} />
);
const ServicesDunning = () => (
  <ReportGroupAppModule reportGroupId="14" leftNavLinkUrl={leftNavUrls.all.services.dunning} />
);
const ServicesInvoicingDefault = () => (
  <ReportGroupAppModule reportGroupId="4" leftNavLinkUrl={leftNavUrls.all.services.invoicing.default} />
);
const ServicesInvoicingOem = () => (
  <ReportGroupAppModule reportGroupId="19" leftNavLinkUrl={leftNavUrls.all.services.invoicing.oem} />
);
const ServicesInvoicingCtp = () => (
  <ReportGroupAppModule reportGroupId="12" leftNavLinkUrl={leftNavUrls.all.services.invoicing.ctp} />
);
const ServicesInvoicingEa = () => (
  <ReportGroupAppModule reportGroupId="9" leftNavLinkUrl={leftNavUrls.all.services.invoicing.ea} />
);
const ServicesInvoicingModern = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "42" : "4"}
    leftNavLinkUrl={leftNavUrls.all.services.invoicing.modern}
  />
);
const ServicesInvoicingCentaur = () => (
  <ReportGroupAppModule reportGroupId="26" leftNavLinkUrl={leftNavUrls.all.services.invoicing.centaur} />
);
const ServicesEnterpriseCommerceDefault = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "31" : "3"}
    leftNavLinkUrl={leftNavUrls.all.services.enterpriseCommerce.default}
  />
);
const ServicesFinancialsPipeline = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "22" : "21"}
    leftNavLinkUrl={leftNavUrls.all.services.financialsPipeline}
  />
);
const SapTaxReport = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "41" : "31"}
    leftNavLinkUrl={leftNavUrls.all.services.billing.saptax}
  />
);
const ServicesBilling = () => (
  <ReportGroupAppModule reportGroupId="0" leftNavLinkUrl={leftNavUrls.all.services.billing.default} />
);
const ReportGroup = (routeProps: RouteComponentProps<IReportGroupAppModuleProps>) => (
  <ReportGroupAppModule reportGroupId={routeProps.match.params.reportGroupId} {...routeProps} />
);
const AnalyticsTokensDefault = () => (
  <ReportGroupAppModule reportGroupId="15" leftNavLinkUrl={leftNavUrls.analytics.tokens.default} />
);
const AnalyticsTokensSubscription = () => (
  <ReportGroupAppModule reportGroupId="20" leftNavLinkUrl={leftNavUrls.analytics.tokens.subscription} />
);
const AnalyticsTokensCsv = () => (
  <ReportGroupAppModule reportGroupId="15" leftNavLinkUrl={leftNavUrls.analytics.tokens.csv} />
);
const AnalyticsEcBizInsights = () => (
  <ReportGroupAppModule reportGroupId="10" leftNavLinkUrl={leftNavUrls.analytics.ecBizInsights} />
);
const AnalyticsLaunchMetrics = () => (
  <ReportGroupAppModule reportGroupId="7" leftNavLinkUrl={leftNavUrls.analytics.launchmetrics} />
);
const SalesMotionNewCommerce = () => (
  <ReportGroupAppModule reportGroupId="7" leftNavLinkUrl={leftNavUrls.salesMotions.newCommerce} />
);
const SalesMotionFieldLedReport = () => (
  <ReportGroupAppModule reportGroupId="24" leftNavLinkUrl={leftNavUrls.salesMotions.fieldLed} />
);
const SalesMotionPartnerLedDrillDownReport = () => (
  <ReportGroupAppModule reportGroupId="27" leftNavLinkUrl={leftNavUrls.salesMotions.partnerLed} />
);
const BfgBusinessReviewReport = () => (
  <ReportGroupAppModule reportGroupId="25" leftNavLinkUrl={leftNavUrls.salesMotions.bfgBizReview} />
);
const FinancialsMrrReport = () => (
  <ReportGroupAppModule reportGroupId="37" leftNavLinkUrl={leftNavUrls.all.services.financials.mrr} />
);
const FinancialsBilledRevenueReport = () => (
  <ReportGroupAppModule reportGroupId="36" leftNavLinkUrl={leftNavUrls.all.services.financials.billedRevenue} />
);
const FinancialsTLDBilledReport = () => (
  <ReportGroupAppModule reportGroupId="35" leftNavLinkUrl={leftNavUrls.all.services.financials.tldBilled} />
);
const AcrReport = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "45" : "32"}
    leftNavLinkUrl={leftNavUrls.detect.anomaliesMetrics}
  />
);
const AnomaliesMetrics = () => (
  <ReportGroupAppModule
    reportGroupId={isProduction ? "21" : "23"}
    leftNavLinkUrl={leftNavUrls.detect.anomaliesMetrics}
  />
);
const AnomaliesControls = (routeProps: RouteComponentProps) => (
  <HealthReport
    {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: isProduction ? "22" : "21" } } }}
  />
);
const AnalyticsRadar = () => <ReportGroupAppModule reportGroupId="1" leftNavLinkUrl={leftNavUrls.analytics.radar} />;
const Controls = (routeProps: RouteComponentProps) => <HealthReport {...routeProps} />;
const Control = (routeProps: RouteComponentProps) => <HealthReport {...routeProps} />;
const ComplianceControls = (routeProps: RouteComponentProps) => (
  <HealthReport
    {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: isProduction ? "17" : "16" } } }}
  />
);
const OperationalControls = (routeProps: RouteComponentProps) => (
  <HealthReport
    {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: isProduction ? "18" : "17" } } }}
  />
);
const MsSalesControls = (routeProps: RouteComponentProps) => (
  <HealthReport
    {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: isProduction ? "21" : "20" } } }}
  />
);
const FinancialIncidentsMonthEndView = (routeProps: RouteComponentProps) => (
  <IncidentsReport {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: "5" } } }} />
);
const FinancialIncidentsExecView = (routeProps: RouteComponentProps) => (
  <IncidentsReport {...{ ...routeProps, match: { ...routeProps.match, params: { viewId: "0" } } }} />
);
const CloseHealthDasboard = (routeProps: RouteComponentProps) => (
  <HealthDashboardPage {...{ ...routeProps, match: { ...routeProps.match, params: { metricGroupId: "1" } } }} />
);
const Tier1HealthDasboard = (routeProps: RouteComponentProps) => (
  <HealthDashboardPage {...{ ...routeProps, match: { ...routeProps.match, params: { metricGroupId: "4" } } }} />
);

const AppRoutes = () => {
  const { changeAppModuleState } = useContext(AppModuleContext);
  const { appState, changeAppState } = useContext(AppContext);
  let urlHash = window.location.hash;
  const queryStringIndex = urlHash?.indexOf("?");

  urlHash = urlHash && urlHash.substring(0, queryStringIndex >= 0 ? queryStringIndex : urlHash.length);

  // Turn off copilot on every page change and let the individual page to turn it on or not.
  useEffect(() => {
    if (urlHash) {
      var appModuleName = urlHash.replace(/[^a-zA-Z0-9]/g, ""),
        incidentViewUrlName = leftNavUrls.all.financialIncidents.replace(/[^a-zA-Z0-9]/g, ""),
        closeDashboardName = leftNavUrls.financialHealth.close.replace(/[^a-zA-Z0-9]/g, ""),
        closeDashboardNameById = leftNavUrls.financialHealth.default.replace(/[^a-zA-Z0-9]/g, "") + "1";

      if (appModuleName === incidentViewUrlName + "0") {
        appModuleName = incidentViewUrlName;
      }

      if (appModuleName === closeDashboardNameById) {
        appModuleName = closeDashboardName;
      }

      let selectedEnvironment = isProduction ? "Prod" : "Int";

      if (appModuleName === closeDashboardName) {
        changeAppState({ useCopilot: true, showCopilotOverride: true, selectedEnvironment });
      } else if (appModuleName?.startsWith(leftNavUrls.financialHealth.default.replace(/[^a-zA-Z0-9]/g, ""))) {
        changeAppState({ useCopilot: false, showCopilotOverride: false, selectedEnvironment });
      } else {
        changeAppState({ useCopilot: false, showCopilotOverride: undefined, selectedEnvironment });
      }

      changeAppModuleState({
        appModuleName,
        copilotInitialMessages: undefined,
        copilotSuggestions: undefined,
        copilotExtraChatMessages: undefined,
      });
    }
  }, [urlHash, appState.isStaging, changeAppState, changeAppModuleState]);

  return (
    <Switch>
      <Route exact path={leftNavUrls.home} component={HomePage} />
      <Route exact path={leftNavUrls.healthDashboardTest} component={HealthDashboardPage} />
      <Route exact path={`${leftNavUrls.financialHealth.close}`} component={CloseHealthDasboard} />
      <Route exact path={`${leftNavUrls.financialHealth.tier1}`} component={Tier1HealthDasboard} />
      <Route exact path={`${leftNavUrls.financialHealth.default}/:metricGroupId?`} component={HealthDashboardPage} />
      <Route path={`${leftNavUrls.all.financialIncidents}/search/:searchText?`} component={IncidentsReport} />
      <Route path={`${leftNavUrls.all.financialIncidents}/:viewId/:incidentId?`} component={IncidentsReport} />
      <Route path={`${leftNavUrls.all.financialIncidents}/incident/:incidentId`} component={IncidentsReport} />
      <Route path={leftNavUrls.all.financialIncidentsMonthEnd} component={FinancialIncidentsMonthEndView} />
      <Route path={leftNavUrls.all.financialIncidents} component={FinancialIncidentsExecView} />
      {/* A function to create component here forces remount of the component. */}
      <Route path={leftNavUrls.all.controlsCompliance} component={ComplianceControls} />
      <Route path={leftNavUrls.all.controlsOperational} component={OperationalControls} />
      <Route path={leftNavUrls.all.controlsMsSales} component={MsSalesControls} />
      <Route path={`${leftNavUrls.all.controls}/:viewId/nodeId=:nodeId`} component={Controls} />
      <Route path={`${leftNavUrls.all.controls}/:viewId?/:controlId?`} component={Controls} />
      <Route path={`${leftNavUrls.all.control}/:controlId`} component={Control} />
      <Route path={leftNavUrls.systemAdmin.debug} component={DebugPage} />
      <Route path={`${leftNavUrls.management.control}/:configItemId`} component={MyControlEdit} />
      <Route path={leftNavUrls.management.control} component={MyControlList} />
      <Route path={`${leftNavUrls.management.incidentView}/:configItemId`} component={MyIncidentViewEdit} />
      <Route path={leftNavUrls.management.incidentView} component={MyIncidentViewList} />
      <Route path={`${leftNavUrls.management.report}/:configItemId`} component={MyReportGroupEdit} />
      <Route path={leftNavUrls.management.report} component={MyReportGroupList} />
      <Route path={`${leftNavUrls.management.anomalyTest}/:configItemId`} component={MyAnomalyTestEdit} />
      <Route path={leftNavUrls.management.anomalyTest} component={MyAnomalyTestList} />
      <Route path={`${leftNavUrls.management.appSetting}/:configItemId`} component={MyAppSettingEdit} />
      <Route path={leftNavUrls.management.appSetting} component={MyAppSettingList} />
      <Route path={`${leftNavUrls.management.collectorService}/:configItemId`} component={MyCollectorServiceEdit} />
      <Route path={leftNavUrls.management.collectorService} component={MyCollectorServiceList} />
      <Route path={`${leftNavUrls.management.controlView}/:configItemId`} component={MyControlViewEdit} />
      <Route path={leftNavUrls.management.controlView} component={MyControlViewList} />
      <Route path={`${leftNavUrls.management.entity}/:configItemId`} component={MyEntityEdit} />
      <Route path={leftNavUrls.management.entity} component={MyEntityList} />
      <Route path={`${leftNavUrls.management.entityView}/:configItemId`} component={MyEntityViewEdit} />
      <Route path={leftNavUrls.management.entityView} component={MyEntityViewList} />
      <Route path={`${leftNavUrls.management.entityTraceView}/:configItemId`} component={MyEntityTraceViewEdit} />
      <Route path={leftNavUrls.management.entityTraceView} component={MyEntityTraceViewList} />
      <Route path={`${leftNavUrls.management.eventSubscription}/:configItemId`} component={MyEventSubscriptionEdit} />
      <Route path={leftNavUrls.management.eventSubscription} component={MyEventSubscriptionList} />
      <Route path={`${leftNavUrls.management.devOpsView}/:configItemId`} component={MyDevOpsViewEdit} />
      <Route path={leftNavUrls.management.devOpsView} component={MyDevOpsViewList} />
      <Route path={`${leftNavUrls.management.checklist}/:configItemId`} component={MyChecklistEdit} />
      <Route path={leftNavUrls.management.checklist} component={MyChecklistList} />
      <Route path={`${leftNavUrls.management.event}/:configItemId`} component={MyEventEdit} />
      <Route path={leftNavUrls.management.event} component={MyEventList} />
      <Route path={`${leftNavUrls.management.serviceContent}/:configItemId`} component={MyServiceContentEdit} />
      <Route path={leftNavUrls.management.serviceContent} component={MyServiceContentList} />
      <Route path={`${leftNavUrls.management.team}/:configItemId`} component={MyTeamEdit} />
      <Route path={leftNavUrls.management.team} component={MyTeamList} />
      <Route path={`${leftNavUrls.management.streamRun}/:configItemId`} component={MyStreamRunEdit} />
      <Route path={leftNavUrls.management.streamRun} component={MyStreamRunList} />
      <Route path={`${leftNavUrls.management.streamSchedule}/:configItemId`} component={MyStreamScheduleEdit} />
      <Route path={leftNavUrls.management.streamSchedule} component={MyStreamScheduleList} />
      <Route path={leftNavUrls.all.reprocessFinancialEntities} component={ReprocessFinancialEntities} />
      <Route path={leftNavUrls.all.servicebusMessageExplorer} component={ServicebusMessageExplorer} />
      <Route path={leftNavUrls.analytics.radar} component={AnalyticsRadar} />
      <Route path={leftNavUrls.analytics.launchmetrics} component={AnalyticsLaunchMetrics} />
      <Route path={leftNavUrls.salesMotions.newCommerce} component={SalesMotionNewCommerce} />
      <Route path={leftNavUrls.salesMotions.partnerLed} component={SalesMotionPartnerLedDrillDownReport} />
      <Route path={leftNavUrls.salesMotions.fieldLed} component={SalesMotionFieldLedReport} />
      <Route path={leftNavUrls.salesMotions.bfgBizReview} component={BfgBusinessReviewReport} />
      <Route path={leftNavUrls.analytics.ecBizInsights} component={AnalyticsEcBizInsights} />
      <Route path={leftNavUrls.analytics.tokens.csv} component={AnalyticsTokensCsv} />
      <Route path={leftNavUrls.analytics.tokens.subscription} component={AnalyticsTokensSubscription} />
      <Route path={leftNavUrls.analytics.tokens.default} component={AnalyticsTokensDefault} />
      <Route path={`${leftNavUrls.all.reportgroup}/:reportGroupId`} component={ReportGroup} />
      <Route path={leftNavUrls.all.services.billing.saptax} component={SapTaxReport} />
      <Route path={leftNavUrls.all.services.billing.default} component={ServicesBilling} />
      <Route path={leftNavUrls.all.services.enterpriseCommerce.default} component={ServicesEnterpriseCommerceDefault} />
      <Route path={leftNavUrls.all.services.financialsPipeline} component={ServicesFinancialsPipeline} />
      <Route path={leftNavUrls.all.services.invoicing.modern} component={ServicesInvoicingModern} />
      <Route path={leftNavUrls.all.services.invoicing.centaur} component={ServicesInvoicingCentaur} />
      <Route path={leftNavUrls.all.services.invoicing.ea} component={ServicesInvoicingEa} />
      <Route path={leftNavUrls.all.services.invoicing.ctp} component={ServicesInvoicingCtp} />
      <Route path={leftNavUrls.all.services.invoicing.oem} component={ServicesInvoicingOem} />
      <Route path={leftNavUrls.all.services.invoicing.default} component={ServicesInvoicingDefault} />
      <Route path={leftNavUrls.all.services.financials.mrr} component={FinancialsMrrReport} />
      <Route path={leftNavUrls.all.services.financials.billedRevenue} component={FinancialsBilledRevenueReport} />
      <Route path={leftNavUrls.all.services.financials.tldBilled} component={FinancialsTLDBilledReport} />
      <Route path={leftNavUrls.all.services.acr} component={AcrReport} />
      <Route path={leftNavUrls.all.services.dunning} component={ServicesDunning} />
      <Route path={leftNavUrls.all.services.modernFinancials} component={ServicesModernFinacials} />
      <Route path={leftNavUrls.all.services.tax} component={ServicesTax} />
      <Route path={leftNavUrls.detect.anomaliesMetrics} component={AnomaliesMetrics} />
      <Route path={leftNavUrls.detect.anomaliesControls} component={AnomaliesControls} />
      {/* Always reload GraphHome when viewId is specified. */}
      <Route path={`${leftNavUrls.all.graph}/viewId=:viewId?`} component={GraphHome} />
      <Route path={leftNavUrls.all.graphEntityLookup} component={GraphHome} />
      <Route path={`${leftNavUrls.all.graph}/:entityType?/:entityId?/:entityVersion?`} component={GraphHome} />
      <Route path={`${leftNavUrls.management.configHistory}/:configItemType/:configItemId`} component={ConfigHistory} />
      <Route path={`${leftNavUrls.works.featureRequests}/:devOpsViewId?`} component={FeatureRequests} />
      <Route path={`${leftNavUrls.works.home}/:devOpsViewId?`} component={DevOpsView} />
      <Route path={`${leftNavUrls.all.okr}/:teamName?`} component={OkrHome} />
      <Route path={`${leftNavUrls.all.fmecloseissues}`} component={FmeCloseIssuesHome} />
      <Route path={`${leftNavUrls.all.search}`} component={SearchHome} />
      <Route path={`${leftNavUrls.streams}/:teamId?`} component={StreamsHome} />
      <Route path={leftNavUrls.all.auditOfAudits} component={AuditOfAuditsPage} />
      <Route component={UnderConstructionPage} />
    </Switch>
  );
};

export default AppRoutes;
