import { Account } from "msal/lib-es6";
import { INavLink } from "@fluentui/react/lib/Nav";
import { IHealthMetricGroup, IHealthMetricSet, IHealthMetricType } from "../appModules/home/HealthDashboard.types";
import { IField } from "../shared/components/DataForm";

export const copilotFeatureFlagStorageName = "copilotFeatureFlag";

export enum DeploymentMethod {
  directCopy,
}

export interface IAppState {
  login_user?: Account | null;
  login_user_photo?: string;
  login_user_info?: IUserInfo;
  selected_left_nav_link?: INavLink;
  left_nav_parents?: string[];
  left_nav_collapsed?: boolean;
  is_small_screen?: boolean;
  is_staging?: boolean;
  is_production?: boolean;
  is_prod_secondary?: boolean;
  system_messages?: ISystemMessage[];
  feature_flags?: IFeatureFlags;
  health_metric_types?: IHealthMetricType[];
  health_metric_groups?: IHealthMetricGroup[];
  health_metric_sets?: IHealthMetricSet[];
}

export interface IUserInfo {
  userRoles?: string[];
  canCreateControl?: boolean;
  isSuperAdmin?: boolean;
  hasMsSalesFullAccess?: boolean;
  appEnvironment?: string;
  isNonGlobalEnvironment?: boolean;
  error?: string;
}

export interface ISystemMessage {
  pageName?: string;
  messageType?: string;
  message?: string;
}

export type Dictionary<T> = Record<string, T>;

export interface IConfigEnvironment {
  name: string;
  writeAccess: boolean;
  default: boolean;
}

export interface IFeatureFlags {
  disableHealthDashboard?: boolean;
  disableCopilot?: boolean;
  disableReprocessFinancialEntities?: boolean;
  disableServiceBusMessageExplorer?: boolean;
  enableNonGlobalConfig?: boolean;
}

export interface IEnvironment {
  name: string;
  configTypes: IConfigType[];
  deploymentSettings?: IDeploymentSettings;
}

export interface IConfigType {
  configType: string;
  fields?: IField[];
}

export interface IDeploymentSettings {
  method?: DeploymentMethod;
  cosmosDb?: ICosmosDbSettings;
}

export interface ICosmosDbSettings {
  hostAddress?: string;
  database?: string;
}
