import { IField, FieldType, IDataContext } from "../../../shared/components/DataForm";
import {
  IAttribute,
  IAttributeFilter,
  IFilterSet,
  IAttributeMappings,
  IServiceConfig,
  ISlaRule,
  IMetric,
  Types,
  AttributeOperator,
  SqlDataTypeOptions,
  SqlAggragateOperators,
  IAlternateKey,
  IStats,
  ISourceEntity,
} from "../../common/serviceContentInterfaces";
import { DataTypeOptions, FilterOperator, SeriesType } from "../../common/interfaces";
import { ItemDisplayView } from "../../../shared/components/DataForm/ItemContainer";
import { ContentDisplayType, getUniqueValues, getValueOrDefault } from "../../../shared/utilities/miscHelper";
import { IDropdownOption } from "@fluentui/react";
import { logicalOperators } from "../../graph/helper";
import {
  checkIfUnique,
  makeOption,
  getTilesFields as getCommonTileFields,
  getCommonStatsFields,
} from "../common/helper";

export const defaultServiceContent: IServiceConfig = {
  id: "new",
};

export const getSourceConfig = (configServices: IServiceConfig[], sourceEntityType: string): IServiceConfig => {
  if (sourceEntityType) {
    return configServices?.find((config) => config.name === sourceEntityType);
  }
  return null;
};

export const getAttributeOptionsByType = (config: IServiceConfig, type: string): IDropdownOption[] => {
  let options: string[] = [];
  if (config?.attributeMappings?.length) {
    config.attributeMappings.forEach((attributeMap) => {
      attributeMap?.attributes?.forEach((attribute) => {
        if (attribute?.type === type) {
          options = options.concat(attribute?.name);
        }
      });
    });
  }
  const uniqueValues = getUniqueValues(options, true);
  return uniqueValues.map((name) => makeOption(name));
};

export const getSourceConfigOptions = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  dataContext: IDataContext
): IDropdownOption[] => {
  const context: ISourceEntity = dataContext?.context;
  let options: string[] = [];
  let usedSourceEntities = serviceConfig?.sourceEntities?.map((sourceEntity) => sourceEntity.type);
  let allSourceEntities = serviceConfigs?.map((config) => config.name);
  allSourceEntities?.forEach((sourceEntity) => {
    let found = !sourceEntity;
    if (sourceEntity !== serviceConfig.name && sourceEntity !== context?.type) {
      usedSourceEntities?.forEach((usedEntity) => {
        if (usedEntity === sourceEntity) {
          found = true;
        }
      });
    }
    if (!found) {
      options.push(sourceEntity);
    }
  });
  const uniqueValues = getUniqueValues(options, true);
  return uniqueValues.map((name) => makeOption(name));
};

export const getUniqueExportAttributeOptions = (
  serviceConfig: IServiceConfig,
  checkValue?: (attribute: IAttribute) => boolean
): IDropdownOption[] => {
  let options: string[] = [];
  const sortArray = true;
  if (serviceConfig?.attributeMappings?.length) {
    serviceConfig.attributeMappings.forEach((attributeMap) => {
      attributeMap?.attributes?.forEach((attribute) => {
        if (attribute?.export && (!checkValue || checkValue(attribute))) {
          options.push(attribute?.name);
        }
      });
    });
  }
  const uniqueValues = getUniqueValues(options, sortArray);
  return uniqueValues.map((name) => makeOption(name));
};

export const getUniqueAttributeOptions = (serviceConfig: IServiceConfig): IDropdownOption[] => {
  let options: string[] = [];
  const sortArray = true;
  if (serviceConfig?.attributeMappings?.length) {
    serviceConfig.attributeMappings.forEach((attributeMap) => {
      var nextSet = attributeMap?.attributes?.map((attribute) => attribute?.name);
      options = options.concat(nextSet);
    });
  }
  const uniqueValues = getUniqueValues(options, sortArray);
  return uniqueValues.map((name) => makeOption(name));
};

export const getAttributeConfig = (serviceConfig: IServiceConfig, attributeName: string): IDropdownOption[] => {
  var result = null;
  if (serviceConfig?.attributeMappings?.length) {
    serviceConfig.attributeMappings.forEach((attributeMap) => {
      attributeMap?.attributes?.forEach((attribute) => {
        if (attribute?.name === attributeName) {
          result = attribute;
        }
      });
    });
  }

  return result;
};

const getFilterSetOptions = (serviceConfig: IServiceConfig): IDropdownOption[] => {
  let options: string[] = [];
  if (serviceConfig?.filterSets?.length) {
    serviceConfig.filterSets.forEach((filterSet) => {
      if (filterSet?.name) {
        options = options.concat(filterSet.name);
      }
    });
  }
  options.push("");
  const uniqueValues = options.sort();
  return uniqueValues.map((name) => makeOption(name));
};

const getMetricIdOptions = (serviceConfig: IServiceConfig): IDropdownOption[] => {
  let options: string[] = [];
  if (serviceConfig?.metrics?.length) {
    serviceConfig.metrics.forEach((metric) => {
      if (metric?.id) {
        options = options.concat(metric.id);
      }
    });
  }
  const uniqueValues = options.sort();
  return uniqueValues.map((id) => makeOption(id));
};

const getTypes = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in Types) {
    if (typeof Types[value] !== "string") {
      options.push(makeOption(value));
    }
  }
  return options;
};

const getSeriesTypeOptions = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in SeriesType) {
    options.push(makeOption(SeriesType[value]));
  }
  return options;
};

const getLogicalOperators = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in logicalOperators) {
    options.push(makeOption(logicalOperators[value]));
  }
  return options;
};

const getDataTypes = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in DataTypeOptions) {
    options.push({
      key: value,
      text: value === "" ? "string" : value,
      ariaLabel: value,
    });
  }
  return options;
};

const getOperators = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in AttributeOperator) {
    if (typeof AttributeOperator[value] !== "string") {
      options.push(makeOption(value));
    }
  }
  return options;
};

const getSqlDataTypes = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in SqlDataTypeOptions) {
    options.push(makeOption(value));
  }
  return options;
};

const getAggragateOperators = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in SqlAggragateOperators) {
    options.push(makeOption(value));
  }
  return options;
};

const getFilterOperators = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in FilterOperator) {
    if (typeof FilterOperator[value] !== "string") {
      options.push(makeOption(value));
    }
  }
  return options;
};

const getContentDisplayTypes = (): IDropdownOption[] => {
  const options: IDropdownOption[] = [];
  for (const value in ContentDisplayType) {
    options.push(makeOption(ContentDisplayType[value]));
  }
  return options;
};

const getStatsTypeOptions = (): IDropdownOption[] => {
  return [makeOption("AttributeStats"), makeOption("EntityMetrics")];
};

const getCustomFilterTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
  const filterSet: IFilterSet = dataContext?.context;
  const filter: IAttributeFilter = filterSet?.filters?.length > index ? filterSet.filters[index] : null;
  const logicalOperator = index > 0 ? `${filter.logicalOperator || "and"} ` : "";
  const prefixTitle = `${logicalOperator}${filter.attribute || "[Attribute]"}`;
  return filter?.filterSet
    ? `${logicalOperator}${filter?.filterSet}`
    : `${prefixTitle} ${filter.operator || "equals"} ${getValueOrDefault(filter?.value, "[Value]")}`;
};

const checkIfExtendedPathIsValue = (dataContext: IDataContext, value: any) => {
  if (value) {
    var attribute: IAttribute = dataContext?.context;

    if (attribute?.extendedPath?.trim() && !attribute?.export) {
      return {
        passed: false,
        message: "If 'extendedPath' has a value then export must be set to true",
      };
    }
    return {
      passed: true,
    };
  }
};

const checkIfExportIsValid = (dataContext: IDataContext, value: any) => {
  if (value) {
    try {
      var attribute: IAttribute = dataContext?.context;
      var attributeMapping: IAttributeMappings = dataContext?.parentDataContext?.context;
      var attributes: IAttribute[] = attributeMapping.attributes;
      var path = attribute?.path;
      var regex: RegExp = new RegExp(/\{(.+?)\}/g);
      var param = regex.exec(path);
      var found = false;
      var errorMsg = "Cannot export if path is reliant on a `{variable}` that is not part of the config";

      var checkForAttributeMatch = (attributeToCheck) => {
        if (attributeToCheck.name === param[1]) {
          const newDataContext: IDataContext = { ...dataContext, context: { ...attributeToCheck, export: true } };
          const valid = checkIfExportIsValid(newDataContext, true);
          if (valid?.passed) {
            return true;
          } else {
            errorMsg =
              valid?.message ||
              "Cannot export if path is reliant on a path that has a {variable} that is not part of the config";
          }
        }
        return false;
      };

      while (param?.length > 1) {
        if (attribute.name === param[1]) {
          return {
            passed: false,
            message: "Circular Reference: Cannot have a parameter that points to itself.",
          };
        }
        found = attributes.some(checkForAttributeMatch);
        if (!found) {
          return {
            passed: false,
            message: errorMsg,
          };
        }
        param = regex.exec(path);
      }
    } catch (ex: any) {
      return {
        passed: false,
        message: `Unexpected error${ex.message ? `: ${ex?.message}` : ""}`,
      };
    }

    return {
      passed: true,
    };
  }
};

export const getAttributeMappingFields = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[]
): IField[] => {
  const getAttributeMappingsNameForTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const attributeMappings: IAttributeMappings = dataContext?.context;
    return attributeMappings?.schemaVersion ? `Schema ${attributeMappings.schemaVersion}` : "Default Schema";
  };
  const getAttributeNameForTitle = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const attributeMappings: IAttributeMappings = dataContext?.context;
    const attribute: IAttribute = attributeMappings?.attributes[index];
    return attribute?.name;
  };
  return [
    {
      fieldName: "attributeMappings",
      label: "Attribute Mappings",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.list,
      itemTypeName: "Attribute Mapping",
      customTitle: getAttributeMappingsNameForTitle,
      customTabHeader: getAttributeMappingsNameForTitle,
      fields: [
        {
          fieldName: "schemaVersion",
          label: "Schema Version",
          fieldType: FieldType.text,
          width: "200px",
          checkIfValid: (dataContext: IDataContext, value: any) =>
            checkIfUnique(dataContext, value, "attributeMappings", "schemaVersion"),
        },
        {
          fieldName: "attributes",
          label: "Attributes",
          fieldType: FieldType.items,
          collapsed: false,
          itemDisplayView: ItemDisplayView.list,
          itemTypeName: "Attribute",
          customTitle: getAttributeNameForTitle,
          customTabHeader: getAttributeNameForTitle,
          fields: [
            {
              fieldName: "name",
              label: "Name",
              required: true,
              width: "150px",
              checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "attributes"),
            },
            {
              fieldName: "path",
              label: "Path",
              width: "300px",
            },
            {
              fieldName: "value",
              label: "Value",
              visible: "!this.path",
              width: "100px",
            },
            {
              fieldName: "type",
              label: "Type",
              fieldType: FieldType.enumeration,
              options: getTypes(),
              width: "200px",
            },
            {
              fieldName: "required",
              fieldType: FieldType.boolean,
              label: "Required",
              width: "100px",
            },
            {
              fieldName: "export",
              label: "Export",
              fieldType: FieldType.boolean,
              width: "60px",
              checkIfValid: checkIfExportIsValid,
            },
            {
              fieldName: "dataType",
              label: "Data Type",
              fieldType: FieldType.enumeration,
              width: "250px",
              options: getDataTypes(),
              visible: "this.export",
              defaultValue: "string",
            },
            {
              label: "Advanced",
              fieldType: FieldType.container,
              collapsible: true,
              fields: [
                {
                  fieldName: "operator",
                  label: "Operator",
                  fieldType: FieldType.enumeration,
                  options: getOperators(),
                  defaultValue: "equals",
                  width: "250px",
                },
                {
                  fieldName: "delimiter",
                  label: "Delimiter",
                  width: "60px",
                  visible: "this.operator === 'Concat'",
                },
                {
                  fieldName: "operatorPropName",
                  label: "Operator Prop Name",
                  width: "150px",
                  visible: "this.operator === 'ArrayMax'",
                  hints: "Prop name used for 'ArrayMax' to determine the value, such as for 'version'",
                },
                {
                  fieldName: "operatorValuePath",
                  label: "Operator Value Path",
                  width: "150px",
                  visible:
                    "(this.operator === 'AddMonth' || this.operator === 'AddDay' || this.operator === 'ReplaceDay')",
                  hints: "Path to get the value to be used if needed for the 'operator'.",
                },
                {
                  fieldName: "operatorValue",
                  label: "Operator Value",
                  width: "100px",
                  visible:
                    "(this.operator === 'AddMonth' || this.operator === 'AddDay' || this.operator === 'ReplaceDay') && !this.operatorValuePath",
                  hints: "Value to be used if needed for the 'operator'. Superceeded by 'operatorValuePath'.",
                },
                {
                  fieldName: "displayColumn",
                  label: "Display Column",
                  fieldType: FieldType.boolean,
                  width: "100px",
                  hints:
                    "Enable this attribute to be display as a data column in the Related Entities table of the Commerce Graph.",
                },
                {
                  fieldName: "indexed",
                  label: "Indexed",
                  fieldType: FieldType.boolean,
                  width: "100px",
                  hints:
                    "Enable this attribute to be indexed for faster query performance when using this attribute as filter. " +
                    "However, too many indexed attributes could reverse the perf benefit.",
                },
                {
                  fieldName: "index",
                  label: "Index",
                  fieldType: FieldType.number,
                  width: "100px",
                  hints: "Index order",
                },
                {
                  fieldName: "pathRegex",
                  label: "Path Regex",
                  hints: "Regex for matching or doing replacements for the path",
                },
                {
                  fieldName: "pathRegexOperation",
                  label: "Path Regex Operation",
                  fieldType: FieldType.enumeration,
                  options: [makeOption("match"), makeOption("replace")],
                  visible: "!!this.pathRegex",
                  defaultValue: "match",
                  hints:
                    "If using match, whatever matches the regex will then be compared to the 'path'" +
                    "to see if it is the corresponding attribute." +
                    "If using replace, the pathRegexOperationValue will be used to replace whatever the regex matched, " +
                    "and the resulting value will be used instead.",
                },
                {
                  fieldName: "pathRegexOperationValue",
                  label: "Path Regex Operation Value",
                  visible: '!!this.pathRegex && this.pathRegexOperation==="replace"',
                  hints: "Value used for replacement on a replace regex",
                },
                {
                  fieldName: "filterType",
                  label: "Filter Type",
                  fieldType: FieldType.enumeration,
                  options: [makeOption("firstMatch")],
                  hints: "Select firstMatch if filters should return the value of the first matching condition",
                },
                {
                  fieldName: "extendedPath",
                  label: "Extended Path",
                  width: "300px",
                  checkIfValid: checkIfExtendedPathIsValue,
                },
                getFilterItemsField(serviceConfig, serviceConfigs, false),
                {
                  label: "Source Entity",
                  fieldType: FieldType.container,
                  collapsible: true,
                  fields: [
                    {
                      fieldName: "sourceEntity",
                      fieldType: FieldType.context,
                      fields: getSourceEntityFields(serviceConfig, serviceConfigs, true, false),
                    },
                  ],
                },
                {
                  label: "Config Source",
                  fieldType: FieldType.container,
                  collapsible: true,
                  fields: [
                    {
                      fieldName: "configSource",
                      fieldType: FieldType.context,
                      fields: [
                        {
                          fieldName: "name",
                          label: "name",
                          width: "200px",
                        },
                        {
                          fieldName: "type",
                          label: "type",
                          width: "200px",
                          fieldType: FieldType.enumeration,
                          options: [makeOption("lookup")],
                          defaultValue: "lookup",
                        },
                      ],
                    },
                  ],
                },
                getExternalConfigField(),
              ],
            },
          ],
        },
      ],
    },
  ];
};

const getExternalConfigField = (): IField => {
  return {
    label: "External Config",
    fieldType: FieldType.container,
    collapsible: true,
    fields: [
      {
        fieldName: "externalConfig",
        fieldType: FieldType.context,
        fields: [
          {
            fieldName: "sourceType",
            label: "Source Type",
            fieldType: FieldType.enumeration,
            options: [makeOption("Storage"), makeOption("AzureTable")],
          },
          {
            fieldName: "endpointUrl",
            label: "Endpoint URL",
            width: "400px",
          },
          {
            fieldName: "tableName",
            label: "Table Name",
          },
          {
            fieldName: "sasKeyVaultSecretName",
            label: "SAS KeyVault Secret Name",
            width: "300px",
          },
          {
            fieldName: "partitionKeyAttribute",
            label: "Partition Key Attribute",
          },
          {
            fieldName: "rowKeyAttribute",
            label: "Row Key Attribute",
          },
          {
            fieldName: "type",
            label: "Type",
            fieldType: FieldType.enumeration,
            options: [makeOption("isPathFound"), makeOption("isPathNotFound")],
          },
        ],
      },
    ],
  };
};

const getFilterField = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showFilterSets: boolean,
  level: number = 0
): IField[] => {
  const filterSetOptions = getFilterSetOptions(serviceConfig);
  const standardFields: IField[] = [
    {
      fieldName: "filterSet",
      label: "Filter Set",
      fieldType: FieldType.enumeration,
      width: "250px",
      visible: showFilterSets && filterSetOptions?.length > 1,
      options: filterSetOptions,
    },
    {
      fieldName: "attribute",
      label: "Attribute",
      fieldType: FieldType.enumeration,
      width: "250px",
      options: getUniqueAttributeOptions(serviceConfig),
      visible: "!this.filterSet",
    },
    {
      fieldName: "value",
      label: "Value",
      fieldType: FieldType.text,
      width: "250px",
      visible: "!this.filterSet",
    },
    {
      fieldName: "operator",
      label: "Operator",
      fieldType: FieldType.enumeration,
      width: "160px",
      options: getFilterOperators(),
      caseInsensitive: true,
      visible: "!this.filterSet",
      defaultValue: "Equals",
    },
    {
      fieldName: "logicalOperator",
      label: "Logical Operator",
      fieldType: FieldType.enumeration,
      options: getLogicalOperators(),
      defaultValue: "and",
    },
    {
      fieldName: "returnValue",
      label: "Return Value",
      visible: "!this.filterSet",
    },
    {
      fieldName: "returnValueAttribute",
      label: "Return Value Attribute",
      fieldType: FieldType.enumeration,
      options: getUniqueAttributeOptions(serviceConfig),
      visible: "!this.filterSet",
    },
  ];
  const newitemName = `Filter Level${level + 2}`;
  const recursed =
    level < 2
      ? [
          {
            fieldName: "filters",
            label: "Filters",
            fieldType: FieldType.items,
            itemDisplayView: ItemDisplayView.list,
            itemTypeName: newitemName,
            fields: getFilterField(serviceConfig, serviceConfigs, showFilterSets, level + 1),
            customTitle: getCustomFilterTitle,
          } as IField,
        ]
      : [];
  return [...standardFields, ...recursed];
};

export const getTileFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField => {
  const commonTileField: IField = getCommonTileFields(serviceConfig?.tiles);
  const newFields: IField[] = [
    {
      fieldName: "metricIds",
      label: "Metric Ids",
      fieldType: FieldType.enumeration,
      width: "250px",
      multiSelect: true,
      options: getMetricIdOptions(serviceConfig),
    },
    {
      fieldName: "seriesType",
      label: "SeriesType",
      fieldType: FieldType.enumeration,
      width: "250px",
      options: getSeriesTypeOptions(),
      defaultValue: "dailyTrend",
    },
    {
      fieldName: "order",
      label: "Order",
      fieldType: FieldType.number,
      width: "250px",
    },
  ];
  commonTileField.collapsed = false;
  // Insert new fields in the position 2 (after visualType and name)
  if (typeof commonTileField.fields === "object") {
    commonTileField.fields.splice(2, 0, ...newFields);
  } else {
    console.error(
      "getCommonTileFields().fields is expected to be an object. If it is going to be dynamic this function needs to be changed"
    );
  }
  return commonTileField;
};

const getFilterItemsField = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showFilterSets: boolean
) => ({
  fieldName: "filters",
  label: "Filters",
  fieldType: FieldType.items,
  itemDisplayView: ItemDisplayView.list,
  itemTypeName: "Filter",
  collapsed: true,
  fields: getFilterField(serviceConfig, serviceConfigs, showFilterSets),
  customTitle: getCustomFilterTitle,
  customTabHeader: (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const filterSet: IFilterSet = dataContext?.context;
    const filter: IAttributeFilter = filterSet?.filters?.length > index ? filterSet.filters[index] : null;
    return `${filter.attribute}`;
  },
});

const getFilterSetFields = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showFilterSets: boolean
): IField[] => {
  return [
    {
      fieldName: "name",
      label: "Name",
      fieldType: FieldType.text,
      width: "250px",
      required: true,
      checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "filterSets"),
    },
    getFilterItemsField(serviceConfig, serviceConfigs, showFilterSets),
  ];
};

const getAlternateKeyFields = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showFilters: boolean
): IField[] => {
  const fields = [
    {
      fieldName: "alternateKeyType",
      label: "Alternate Key Type",
      fieldType: FieldType.text,
      regEx: "^[a-zA-Z.]+$",
      hints: "Alphanumeric string used as a lookup id. Use camelCasing. Periods are also allowed.",
      width: "200px",
      checkIfValid: (dataContext: IDataContext, value: any) =>
        checkIfUnique(dataContext, value, "alternateKeys", "alternateKeyType"),
    },
    {
      fieldName: "alternateKeyIdAttribute",
      label: "Alternate Key Id Attribute",
      fieldType: FieldType.enumeration,
      width: "200px",
      options: [...getUniqueAttributeOptions(serviceConfig)],
      visible: "!!this.alternateKeyType",
      required: (dataContext: IDataContext) => {
        const alternateKey: IAlternateKey = dataContext.context;
        return !!alternateKey?.alternateKeyType;
      },
    },
    {
      fieldName: "alternateKeyVersionAttribute ",
      label: "Alternate Key Version Attribute",
      fieldType: FieldType.enumeration,
      width: "200px",
      options: [...getUniqueAttributeOptions(serviceConfig), makeOption("")],
      visible: "!!this.alternateKeyType",
    },
  ];
  const filterFields = getFilterItemsField(serviceConfig, serviceConfigs, true);

  return showFilters ? [...fields, filterFields] : fields;
};

export const getAlternateKeysFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField[] => {
  const getName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const serviceConfig: IServiceConfig = dataContext?.context;
    const alternateKey: IAlternateKey =
      serviceConfig?.alternateKeys?.length > index ? serviceConfig?.alternateKeys[index] : null;
    return alternateKey?.alternateKeyVersionAttribute;
  };

  return [
    {
      fieldName: "alternateKeys",
      label: "Alternate Keys",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Alternate Keys",
      hints: "Alternate keys that will be used for joining with other service entities",
      customTitle: getName,
      customTabHeader: getName,
      fields: getAlternateKeyFields(serviceConfig, serviceConfigs, true),
    },
  ];
};

const getSourceEntityFields = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showValueAttribute: boolean,
  requireType: boolean
): IField[] => {
  return [
    {
      fieldName: "type",
      label: "Type",
      fieldType: FieldType.enumeration,
      width: "250px",
      required: requireType,
      options: (dataContext: IDataContext) => getSourceConfigOptions(serviceConfig, serviceConfigs, dataContext),
    },
    {
      fieldName: "idAttribute",
      label: "Id Attribute",
      fieldType: FieldType.enumeration,
      width: "200px",
      options: [...getUniqueAttributeOptions(serviceConfig), makeOption("")],
      visible: "!!this.type",
    },
    {
      fieldName: "versionAttribute",
      label: "Version Attribute",
      fieldType: FieldType.enumeration,
      width: "200px",
      options: getUniqueAttributeOptions(serviceConfig),
      visible: "!!this.idAttribute && !!this.type",
    },
    {
      fieldName: "valueAttribute",
      label: "Value Attribute",
      fieldType: FieldType.text,
      width: "200px",
      visible: showValueAttribute,
    },
    ...getAlternateKeyFields(serviceConfig, serviceConfigs, false),
  ];
};

export const getSlaRulesFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField[] => {
  const getName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const serviceConfig: IServiceConfig = dataContext?.context;
    const slaRule: ISlaRule = serviceConfig?.slaRules?.length > index ? serviceConfig?.slaRules[index] : null;
    return slaRule?.name;
  };
  return [
    {
      fieldName: "slaRules",
      label: "Sla Rules",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Sla Rule",
      hints: "SLA rules for verifying SLAs to downstream services",
      customTitle: getName,
      customTabHeader: getName,
      fields: [
        {
          fieldName: "name",
          label: "Name",
          fieldType: FieldType.text,
          width: "250px",
          required: true,
          checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "slaRules"),
        },
        {
          fieldName: "targetEntityType",
          label: "Target Entity Type",
          fieldType: FieldType.enumeration,
          width: "250px",
          options: serviceConfigs ? serviceConfigs.map((c) => makeOption(c.name)) : [],
        },
        {
          fieldName: "slaTimeframe",
          label: "SLA Timeframe",
          fieldType: FieldType.text,
          width: "100px",
          valueWidth: "100px",
          regEx: "^([0-9]+([.][0-9]+)?[dh])?$",
          visible: "!this.slaDayOfNextMonth",
          hints: "Should be a number followed a letter (h=hours, d=days). For example: '2d'",
        },
        {
          fieldName: "slaDayOfNextMonth",
          label: "SLA Day of the Next Month",
          fieldType: FieldType.text,
          width: "180px",
          valueWidth: "180px",
          regEx: "^[0-9][0-9]?$",
          visible: "!this.slaTimeframe",
          hints: "The day of the next month for the SLA (such as a 5 for the 5th of the following month)",
        },
        {
          fieldName: "slaTimestampAttribute",
          label: "SLA Timestamp Attribute",
          fieldType: FieldType.enumeration,
          width: "250px",
          options: getUniqueAttributeOptions(serviceConfig),
        },
        {
          fieldName: "groupingKeys",
          label: "Grouping Keys",
          fieldType: FieldType.enumeration,
          multiSelect: true,
          options: getUniqueAttributeOptions(serviceConfig),
          width: "300px",
        },
        getFilterItemsField(serviceConfig, serviceConfigs, true),
      ],
    },
  ];
};

export const getStatsFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField[] => {
  return [
    {
      fieldName: "stats",
      label: "Stats",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Stats",
      hints:
        "Collect aggregated data on our financial entities on various dimensions, e.g. time and any entity attributes.",
      customTitle: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const stat: IStats = serviceConfig?.stats?.length > index ? serviceConfig?.stats[index] : null;
        return stat?.id; //Should this have more information or just be the same. If same, combine.
      },
      customTabHeader: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const stat: IStats = serviceConfig?.stats?.length > index ? serviceConfig?.stats[index] : null;
        return stat?.id;
      },
      fields: [
        {
          fieldName: "id",
          label: "id",
          fieldType: FieldType.text,
          regEx: "^[a-zA-Z0-9]+$",
          hints: "Alphanumeric string used as a lookup id. Use camelCasing.",
          required: true,
          width: "200px",
          checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "stats", "id"),
        },
        {
          fieldName: "statsType",
          fieldType: FieldType.enumeration,
          label: "Stats Type",
          options: getStatsTypeOptions(),
          width: "160px",
          hints:
            "Select 'AttributeStats' for standard stats calculations based on an attribute, or 'EntityMetrics' for a snapshot of the entity metrics.",
        },
        {
          fieldName: "attribute",
          label: "Attribute",
          fieldType: FieldType.enumeration,
          options: getUniqueExportAttributeOptions(serviceConfig, (attribute) => attribute?.dataType === "number"),
          width: "200px",
          visible: "this.statsType !== 'EntityMetrics'",
        },
        ...getCommonStatsFields(),
        {
          fieldName: "groupByAttributes",
          label: "Group By Attributes",
          fieldType: FieldType.enumeration,
          multiSelect: true,
          width: "200px",
          options: getUniqueExportAttributeOptions(serviceConfig),
          hints:
            "Select one or more attributes to calculate stats based on all combinations of the group by attribute values.",
        },
        {
          fieldName: "maxVersionOnly",
          label: "Max Version Only",
          fieldType: FieldType.boolean,
          visible: "this.statsType === 'EntityMetrics'",
          hints: "Whether to calculate based on the maximum entity version only.",
        },
        {
          fieldName: "anchorDateAttribute",
          label: "Anchor Date Attribute",
          fieldType: FieldType.enumeration,
          options: getUniqueExportAttributeOptions(serviceConfig),
          visible: "this.statsType === 'EntityMetrics'",
          width: "200px",
          hints:
            "Specify optional attribute to be used as reference for the time range. Default to use entity's TimeStamp field.",
        },
        {
          fieldName: "indexedAttributes",
          label: "Indexed Attributes",
          fieldType: FieldType.textArray,
          visible: "this.statsType === 'EntityMetrics'",
          width: "200px",
          hints:
            "Specify optional list of attribute names that are indexed. TODO: Remove this field once indexing is applied automatically for all export attributes.",
        },
        getFilterItemsField(serviceConfig, serviceConfigs, true),
      ],
    },
  ];
};

export const getMetricsFields = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]): IField[] => {
  return [
    {
      fieldName: "metrics",
      label: "Metrics",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "Metrics",
      hints: "Metrics for the service. These can also be used for SLA Rules",
      customTitle: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const metric: IMetric = serviceConfig?.metrics?.length > index ? serviceConfig?.metrics[index] : null;
        return metric?.exportAttribute && metric.operator
          ? `${metric.name ? `${metric.name}: ` : ""}'${metric.operator} of ${metric.exportAttribute}'`
          : metric?.name;
      },
      customTabHeader: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const metric: IMetric = serviceConfig?.metrics?.length > index ? serviceConfig?.metrics[index] : null;
        return metric?.id;
      },
      fields: [
        {
          fieldName: "id",
          label: "id",
          fieldType: FieldType.text,
          regEx: "^[a-zA-Z0-9]+$",
          hints: "Alphanumeric string used as a lookup id.",
          required: true,
          width: "250px",
          checkIfValid: (dataContext: IDataContext, value: any) => checkIfUnique(dataContext, value, "metrics", "id"),
        },
        {
          fieldName: "name",
          label: "Name",
          fieldType: FieldType.text,
          width: "250px",
        },
        {
          fieldName: "exportAttribute",
          label: "Export Attribute",
          fieldType: FieldType.enumeration,
          width: "250px",
          options: getUniqueExportAttributeOptions(serviceConfig),
        },
        {
          fieldName: "operator",
          label: "SQL Operator",
          fieldType: FieldType.enumeration,
          options: getAggragateOperators(),
          width: "250px",
        },
        {
          fieldName: "dataType",
          label: "SQL Data Type",
          fieldType: FieldType.enumeration,
          width: "250px",
          options: getSqlDataTypes(),
          defaultValue: "varchar",
        },
        {
          fieldName: "displayType",
          label: "Display Format",
          fieldType: FieldType.enumeration,
          width: "250px",
          options: getContentDisplayTypes(),
          defaultValue: "text",
        },
        {
          fieldName: "showInGraph",
          label: "Show In Graph",
          fieldType: FieldType.boolean,
          width: "100px",
          hints:
            "Whether to show this metric on the graph. Otherwise, the metric will be displayed only in full metric listing.",
        },
        {
          fieldName: "useDistinct",
          label: "Use Distinct",
          fieldType: FieldType.boolean,
          width: "80px",
          hints: "Whether to aggregate on distinct values, e.g. COUNT on distinct values only",
        },
        {
          fieldName: "useAbsOperator",
          label: "Use ABS Operator",
          fieldType: FieldType.boolean,
          width: "120px",
          hints: "Whether to apply the Absolute operator",
        },
        {
          fieldName: "hidden",
          label: "Hide Metric",
          fieldType: FieldType.boolean,
          width: "80px",
          hints: "Use to hide the metric temporarily. For permanant removal, simply delete the metric from config.",
        },
        {
          fieldName: "sortOrder",
          label: "Sort Order",
          fieldType: FieldType.number,
          width: "120px",
          valueWidth: "60px",
          hints: "Sort order of the metric. Use this to force display sort order of the metrics.",
        },
        getFilterItemsField(serviceConfig, serviceConfigs, true),
      ],
    },
  ];
};

export const getFilterSetField = (serviceConfig: IServiceConfig, serviceConfigs: IServiceConfig[]) => {
  const getName = (dataContext: IDataContext, itemTypeName: string, index: number) => {
    const serviceConfig: IServiceConfig = dataContext?.context;
    const filterSet: IFilterSet[] = serviceConfig?.filterSets;
    return filterSet?.length > index ? filterSet[index].name : "";
  };
  return [
    {
      fieldName: "filterSets",
      label: "Filter Sets",
      fieldType: FieldType.items,
      collapsed: false,
      itemDisplayView: ItemDisplayView.tab,
      itemTypeName: "FilterSet",
      fields: getFilterSetFields(serviceConfig, serviceConfigs, false),
      hints: "Sets of filters that can be reused throughout the config",
      customTitle: getName,
      customTabHeader: getName,
    } as IField,
  ];
};

export const getSourceEntitiesField = (
  serviceConfig: IServiceConfig,
  serviceConfigs: IServiceConfig[],
  showValueAttribute: boolean = false
): IField[] => {
  const requireType = true;
  return [
    {
      fieldName: "sourceEntities",
      label: "Source Entities",
      fieldType: FieldType.items,
      collapsed: false,
      itemTypeName: "Source Entity",
      itemDisplayView: ItemDisplayView.tab,
      fields: getSourceEntityFields(serviceConfig, serviceConfigs, showValueAttribute, requireType),
      hints: "Specify source/upstream entities and the corresponding id and version fields",
      customTabHeader: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const sourceEntities = serviceConfig?.sourceEntities;
        return sourceEntities?.length > index ? sourceEntities[index].type : "";
      },
      customTitle: (dataContext: IDataContext, itemTypeName: string, index: number) => {
        const serviceConfig: IServiceConfig = dataContext?.context;
        const sourceEntities = serviceConfig?.sourceEntities;
        const sourceEntity = sourceEntities?.length > index ? sourceEntities[index] : null;
        let title = sourceEntity?.type
          ? `${sourceEntity.type} on '${sourceEntity.idAttribute || "[idAttribute]"}'`
          : "";
        if (sourceEntity?.type && sourceEntity.alternateKeyType) {
          if (sourceEntity.idAttribute) {
            title = `${title} with '${sourceEntity.alternateKeyType}' alternate key`;
          } else {
            title = `${sourceEntity.type} with '${sourceEntity.alternateKeyType}' alternate key`;
          }
        }
        return title;
      },
    },
  ];
};

export const getBasicFields = (configService: IServiceConfig, configServices: IServiceConfig[]): IField[] => {
  return [
    {
      fieldName: "id",
      label: "ID",
      fieldType: FieldType.displayOnly,
    },
    {
      fieldName: "name",
      label: "Name",
      required: true,
      width: "300px",
      hints:
        "Full name of event (such as for PostInvoice.DocumentCreated)" +
        "Use PascalCasing and only letters and periods.",
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "owner",
      label: "Owner",
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      fillWidth: true,
      lastInRow: true,
    },
    {
      fieldName: "eventType",
      label: "Event Type",
      width: "200px",
      regEx: "^[a-zA-Z.-_]+$",
      hints:
        "This is used when the service has events. " +
        "It should match the value there and is ignored if it is not needed.",
    },
    {
      fieldName: "isProcessValidation",
      label: "Is Process Validation",
      width: "125px",
      fieldType: FieldType.boolean,
    },
    {
      fieldName: "validationCompleteInDays",
      label: "Validation Complete In Days",
      width: "200px",
      fieldType: FieldType.number,
    },
    {
      fieldName: "isCacheEnabled",
      label: "Is Cache Enabled",
      width: "125px",
      fieldType: FieldType.boolean,
      hints: "This is used to cache larger data sets so that they don't have to be pulled every time.",
    },
    {
      fieldName: "cacheTtl",
      label: "Cache TTL",
      width: "125px",
      regEx: "^([0-9]+([.][0-9]+)?[dh])?$",
      visible: "!!this.isCacheEnabled",
      hints:
        "Length of time that the cache is stored." +
        "Should be a number followed a letter (h=hours, d=days). " +
        "For example: '2d' (max time is 2 days).",
    },
  ];
};
