import { ILink, INode } from "../../../shared/components/Diagram";
import { actionOutputPrefix } from "./MyControlEdit.helper";

const defaultNodeWidth = 180;
const defaultXWidth = 220;
const defaultY = 0;

export const getInputNodes = (controlSdkSettings, controlSdk, onClick: Function): INode[] => {
  let inputNodes =
    controlSdkSettings?.entities?.map((entity, index) => ({
      id: entity,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Entities")?.Sources.find(
        (source) => source.Id === entity
      )?.DisplayName,
      x: index * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "darkblue" },
      onClick,
    })) || [];

  let nodeCount = inputNodes?.length || 0;

  inputNodes = inputNodes.concat(
    controlSdkSettings?.datasets?.map((dataset, index) => ({
      id: dataset,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Datasets")?.Sources.find(
        (source) => source.Id === dataset
      )?.DisplayName,
      x: (nodeCount + index) * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "crimson" },
    })) || []
  );

  inputNodes = inputNodes.concat(
    controlSdkSettings?.dimensions?.map((dimension, index) => ({
      id: dimension,
      name: controlSdk?.DataSources?.find((dataSource) => dataSource.Id === "Dimensions")?.Sources.find(
        (source) => source.Id === dimension
      )?.DisplayName,
      x: (nodeCount + index) * defaultXWidth,
      y: defaultY,
      width: defaultNodeWidth,
      style: { backgroundColor: "blueviolet" },
    })) || []
  );

  return inputNodes;
};

export const getActionNodesAndLinks = (
  controlSdkSettings,
  controlSdk,
  inputNamesMap: object,
  onClick: Function
): [INode[], ILink[], number] => {
  let actionRowCount = 0,
    actionNodes = [],
    actionLinks = [],
    actionTargetMap = {};

  controlSdkSettings?.actions?.forEach((action, actionIndex) => {
    let hasInputFromActionOutput = action.input_keys?.find((input) => input.startsWith(actionOutputPrefix))?.length > 0,
      x = (actionIndex - actionRowCount + 1) * defaultXWidth,
      y = defaultY,
      currentActionNodeId = "action." + actionIndex;

    // If no input is from other action output, the action is considered an "anchor" action of a new "train".
    if (!hasInputFromActionOutput) {
      action.input_keys?.forEach((input, inputIndex) => {
        let sourceActionNodeId = `input.${actionIndex}.${inputIndex}`;
        let inputName = inputNamesMap[input];
        let nodeColor = input?.startsWith("Dimensions")
          ? "blueviolet"
          : input?.startsWith("Silver")
          ? "crimson"
          : "darkblue";
        y = actionRowCount * 60 + defaultY;
        x = defaultXWidth;

        actionNodes.push({
          id: sourceActionNodeId,
          name: inputName,
          x: 0,
          y,
          width: defaultNodeWidth,
          style: { backgroundColor: nodeColor },
        });

        actionLinks.push({ fromNodeId: sourceActionNodeId, toNodeId: currentActionNodeId });
        actionRowCount++;
      });
    } else {
      // For non-anchor action, define the node position relative to the parent input(s) and setup the link(s) between input(s) and itself.
      action.input_keys?.forEach((input) => {
        let inputActionNumber = Number(input.replace(actionOutputPrefix, ""));
        let sourceActionNodeId = "action." + (inputActionNumber - 1);
        let sourceTargetCount = actionTargetMap[sourceActionNodeId] || 0;
        let parentNode = actionNodes.find((actionNode) => actionNode.id === sourceActionNodeId);

        if (parentNode) {
          y = parentNode.y * (sourceTargetCount + 1);
          x = parentNode.x + defaultXWidth;

          actionTargetMap[sourceActionNodeId] = sourceTargetCount + 1;

          if (sourceTargetCount > 0) {
            actionRowCount++;
          }

          actionLinks.push({ fromNodeId: sourceActionNodeId, toNodeId: currentActionNodeId });
        }
      });
    }

    actionNodes.push({
      id: currentActionNodeId,
      name: action.customName || controlSdk?.Actions?.find((sdkAction) => sdkAction.Name === action.name)?.DisplayName,
      x,
      y,
      width: defaultNodeWidth,
      style: { backgroundColor: action.name === "summarize" ? "sienna" : "darkgoldenrod" },
      onClick: () => onClick(actionIndex),
    });
  });

  return [actionNodes, actionLinks, actionRowCount];
};

export const getOutputNodes = (controlSdkSettings, controlSdk, onClick: Function): INode[] => {
  return controlSdkSettings?.Outputs?.map((output, index) => ({
    id: "output" + index,
    name: controlSdk?.Outputs?.find((sdkOutput) => sdkOutput.Id === output.Id)?.DisplayName,
    x: index * defaultXWidth,
    y: defaultY,
    width: defaultNodeWidth,
    style: { backgroundColor: "teal" },
    onClick: () => onClick(index),
  }));
};

export const addNewOutput = (control): number => {
  let sdkOutputs = control?.controlSdkSettings?.Outputs;

  if (control?.controlSdkSettings) {
    if (!Array.isArray(sdkOutputs)) {
      sdkOutputs = [];
      control.controlSdkSettings.Outputs = sdkOutputs;
    }

    sdkOutputs.push({});
  }

  return sdkOutputs?.length ? sdkOutputs.length - 1 : undefined;
};

export const deleteOutput = (control, selectedOutputIndex) => {
  let sdkOutputs = control?.controlSdkSettings?.Outputs;

  if (sdkOutputs?.length > selectedOutputIndex) {
    sdkOutputs.splice(selectedOutputIndex, 1);
  }
};

export const addNewAction = (control): number => {
  let sdkActions = control?.controlSdkSettings?.actions ?? [];
  control.controlSdkSettings.actions = sdkActions;

  sdkActions.push({});

  return sdkActions.length - 1;
};

export const deleteAction = (control, selectedActionIndex) => {
  let sdkActions = control?.controlSdkSettings?.actions;

  if (sdkActions?.length > selectedActionIndex) {
    sdkActions.splice(selectedActionIndex, 1);
  }
};
