import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { IState } from "../reducers/interfaces";
import { Account } from "msal/lib-es6";
import { Link } from "react-router-dom";
import { Icon } from "@fluentui/react/lib/Icon";
import { actionCreator } from "./duck";
import UserCallout from "./UserCallout";
import SettingCallout from "./SettingCallout";
import classNames from "./Header.module.scss";
import { useId } from "@fluentui/react-hooks";
import UserFeedbackCallout from "./UserFeedbackCallout";
import { AppContext } from "./App";
import { IUserInfo } from "./interfaces";
import { isUserFinanceOrLT, isUserSoxAuditor } from "./App.helper";

export interface IHeaderState {
  isUserCalloutExpanded: boolean;
  isSettingCalloutExpanded: boolean;
}

export interface IHeaderStateProps {
  user: Account | null;
  userPhoto: string;
  userInfo: IUserInfo;
  isSmallScreen: boolean;
  isStaging: boolean;
  isProduction: boolean;
  isProdSecondary: boolean;
}

export interface IHeaderDispatchProps {
  toggleLeftNav: () => void;
}

const Header = (props: IHeaderStateProps & IHeaderDispatchProps) => {
  const { user, userPhoto, userInfo, isSmallScreen, isStaging, isProduction, isProdSecondary, toggleLeftNav } = props;
  const [isUserCalloutExpanded, setIsUserCalloutExpanded] = useState<boolean>(false);
  const [isSettingCalloutExpanded, setIsSettingCalloutExpanded] = useState<boolean>(false);
  const [showUserFeedback, setShowUserFeedback] = useState<boolean>(false);
  const { appState, changeAppState } = useContext(AppContext);
  const { showCopilot, showCopilotOverride } = appState;
  const userPhotoId = useId("userPhotoLink");
  const settingMenuId = useId("settingMenuLink");
  const feedbackId = useId("feedbackLink");
  const copilotToggleId = useId("copilotToggle");
  const showCopilotFinal = showCopilotOverride !== undefined ? showCopilotOverride : showCopilot;
  const environmentLabel =
    window.location.hostname === "localhost"
      ? "Localhost"
      : isProdSecondary
      ? "Prod-Secondary"
      : (userInfo?.isNonGlobalEnvironment || !isProduction) && userInfo?.appEnvironment
      ? userInfo?.appEnvironment
      : !isProduction
      ? "Non-Prod"
      : undefined;
  const showSettings =
    userInfo && (userInfo.isSuperAdmin || (!isUserSoxAuditor(userInfo) && !isUserFinanceOrLT(userInfo)));

  const onUserPhotoClick = () => {
    setIsUserCalloutExpanded(!isUserCalloutExpanded);
    setIsSettingCalloutExpanded(false);
  };

  const onUserPhotoDismiss = () => {
    setIsUserCalloutExpanded(false);
  };

  const onSettingMenuClick = () => {
    setIsSettingCalloutExpanded(!isSettingCalloutExpanded);
    setIsUserCalloutExpanded(false);
  };

  const onHelpLinkClick = () => window.open("https://aka.ms/commerceradar/wiki");

  const onSettingMenuDismiss = () => {
    setIsSettingCalloutExpanded(false);
  };

  const onFeedbackLinkClick = () => {
    setShowUserFeedback(!showUserFeedback);
    setIsUserCalloutExpanded(false);
    setIsSettingCalloutExpanded(false);
  };

  const onFeedbackDismiss = () => {
    setShowUserFeedback(false);
  };

  const onCopilotToggleClick = () => {
    changeAppState({
      showCopilotOverride: showCopilotOverride === undefined || showCopilotOverride === true ? false : true,
    });
  };

  return (
    <div className={classNames.header}>
      {isSmallScreen && (
        <div className={classNames.navMenu} onClick={toggleLeftNav}>
          <Icon iconName="CollapseMenu" title="Toggle Left Pane" className={classNames.menuButton} />
        </div>
      )}
      <Link to="/">
        <div className={classNames.titlePane}>
          <div className={classNames.title}>Commerce Radar</div>
          {environmentLabel && <div className={classNames.nonProd}>{environmentLabel}</div>}
          {isStaging && <div className={classNames.staging}>Staging</div>}
        </div>
      </Link>
      {user && (
        <div className={classNames.authPane}>
          {/* Temporarily hidden the Search entry UX.
            <Link to="/search">
              <Icon className={classNames.searchIcon} iconName="Search" title="Commerce Search" tabIndex={0} />
            </Link> */}
          {showCopilot && (
            <div
              className={classNames.copilotToggle}
              title={`${showCopilotFinal ? "Hide" : "Show"} Commerce Radar Copilot`}
              tabIndex={0}
              onClick={onCopilotToggleClick}
              onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onCopilotToggleClick()}
              id={copilotToggleId}
            >
              <Icon className={classNames.menuIcon} iconName="ChatBot" />
            </div>
          )}
          {userInfo && userInfo.isNonGlobalEnvironment === false && (
            <>
              {showSettings && (
                <div
                  className={classNames.settingMenu}
                  title="App Settings"
                  tabIndex={0}
                  onClick={onSettingMenuClick}
                  onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onSettingMenuClick()}
                  id={settingMenuId}
                >
                  <Icon className={classNames.menuIcon} iconName="Settings" />
                </div>
              )}
              <div
                className={classNames.helpLink}
                title="Help Info"
                tabIndex={0}
                onClick={onHelpLinkClick}
                onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onHelpLinkClick()}
              >
                <Icon className={classNames.menuIcon} iconName="Help" />
              </div>
              <div
                className={classNames.feedbackLink}
                title="User Feedback"
                tabIndex={0}
                onClick={onFeedbackLinkClick}
                onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onFeedbackLinkClick()}
                id={feedbackId}
              >
                <Icon className={classNames.menuIcon} iconName="Emoji2" />
              </div>
            </>
          )}
          <div
            className={classNames.userPhoto}
            title={user.name + "\n" + user.userName}
            tabIndex={0}
            onClick={onUserPhotoClick}
            onKeyUp={(ev: React.KeyboardEvent) => ev.which === 13 && onUserPhotoClick()}
            id={userPhotoId}
          >
            {userPhoto ? (
              <img src={userPhoto} alt="User" />
            ) : (
              <Icon className={classNames.userIcon} iconName="Contact" />
            )}
          </div>
          {isUserCalloutExpanded && (
            <UserCallout
              user={user}
              userPhoto={userPhoto}
              userInfo={userInfo}
              target={`#${userPhotoId}`}
              onDismiss={onUserPhotoDismiss}
            />
          )}
          {isSettingCalloutExpanded && (
            <SettingCallout isStaging={isStaging} target={`#${settingMenuId}`} onDismiss={onSettingMenuDismiss} />
          )}
          {showUserFeedback && <UserFeedbackCallout target={`#${feedbackId}`} onDismiss={onFeedbackDismiss} />}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState): IHeaderStateProps => ({
  user: state.app.login_user,
  userPhoto: state.app.login_user_photo,
  userInfo: state.app.login_user_info,
  isSmallScreen: state.app.is_small_screen,
  isStaging: state.app.is_staging,
  isProduction: state.app.is_production,
  isProdSecondary: state.app.is_prod_secondary,
});

const mapDispatchToProps = {
  toggleLeftNav: actionCreator.toggleLeftNav,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
