import { IField, FieldType } from "../../../shared/components/DataForm";
import { IControlView } from "../../controls/interfaces";
import {
  getDiagramNodeFields,
  getDiagramLinkFields,
  getDiagramAdvancedFields,
  getColumnFields,
} from "../common/helper";

export const defaultControlView: IControlView = {
  id: "new",
  soxCompliance: true,
};

export const getFields = (controlView: IControlView): IField[] => {
  if (!controlView) return null;

  return [
    {
      fieldName: "id",
      label: "ID",
      fieldType: FieldType.displayOnly,
      value: controlView.id,
    },
    {
      fieldName: "soxCompliance",
      label: "SOX Compliance",
      fieldType: FieldType.boolean,
      lastInRow: true,
      hints: "Indicate if this object is required to meet SOX Compliance standard.",
    },
    {
      fieldName: "name",
      label: "Name",
      value: controlView.name,
      required: true,
      width: "300px",
    },
    {
      fieldName: "owner",
      label: "Owner",
      value: controlView.owner,
      required: true,
      hints: "Alias of the owner",
    },
    {
      fieldName: "editors",
      label: "Editors",
      fieldType: FieldType.textArray,
      value: controlView.editors,
      required: true,
      lastInRow: true,
      hints: "Comma separated list of aliases who can edit this object",
    },
    {
      fieldName: "description",
      label: "Description",
      value: controlView.description,
      lastInRow: true,
    },
    {
      fieldName: "includeMEControlColumn",
      fieldType: FieldType.boolean,
      label: "Include ME Control Column",
      value: controlView.includeMEControlColumn,
      hints: "Include the 'ME Control' column in the control view list.",
    },
    getDiagramAdvancedFields(controlView),
    getDiagramNodeFields(controlView.nodes),
    getDiagramLinkFields(controlView.links),
    getColumnFields(controlView.columns),
  ];
};
