import AuthHelper from "../shared/utilities/authHelper";
import appConfig from "../app.config";
import { appInsights } from "../app/telemetryHelper";
import { getCookie } from "../shared/utilities/miscHelper";

export const noDataErrorMsg = "No data is returned.";
export const noPermissionErrorMsg = "You are not authorized to access this data.";
export const refreshQueryStringKey = "refresh";
export const stagingQueryStringKey = "staging";

export const radarApiIntUrl = "https://api.radar.microsoft-int.com/";
export const radarApiProdUrl = "https://commerceradar-api.azurewebsites.net/";
export const radarApiProdSecondaryUrl = "https://commerceradar-api-secondary.azurewebsites.net/";

const hostname = window.location.hostname;

export const getRadarApiUrl = (hostname: string): string => {
  // Assuming the frontend hostname is in the format of commerceradar-web-{environment}.azurewebsites.net
  // The Api hostname is assumed to be in the format of commerceradar-webapi-{environment}.azurewebsites.net
  let domain = hostname?.split(".")[0],
    domainItems = domain?.split("-"),
    environment = domainItems && domainItems[domainItems.length - 1];
  if (!environment) {
    throw new Error("Invalid hostname format");
  }
  return `https://commerceradar-webapi-${environment}.azurewebsites.net/`;
};

export const radarApiUrl =
  process.env.REACT_APP_RADAR_API_URL ||
  (hostname === "radar.microsoft.com"
    ? radarApiProdUrl
    : hostname === "commerceradar-secondary.azurewebsites.net"
    ? radarApiProdSecondaryUrl
    : hostname === "localhost" || hostname === "radar.microsoft-int.com"
    ? radarApiIntUrl
    : getRadarApiUrl(hostname));

export const authHelper = new AuthHelper(appConfig, (message) => appInsights.trackTrace({ message }));

export enum ResponseDataType {
  none,
  blob,
  json,
  text,
  file,
}

export const fetchDataWithAuth = (
  endpoint: string,
  scopes: string[],
  errorMessage: string,
  dataType?: ResponseDataType,
  options?: any,
  settings?: any
): Promise<Object> => {
  return new Promise((resolve, reject) => {
    authHelper
      .acquireAccessToken(scopes)
      .then((accessToken) => {
        fetchData(endpoint, accessToken, dataType, options, settings)
          .then((data) => resolve(data))
          .catch((error) => {
            appInsights.trackException(error);
            errorHandler(error, reject, errorMessage);
          });
      })
      .catch((error) => {
        appInsights.trackException(error);
        reject(error);
      });
  });
};

export const fetchData = (
  endpoint: string,
  accessToken?: string,
  dataType: ResponseDataType = ResponseDataType.json,
  options?: any,
  settings?: any
): Promise<Response | Object> => {
  let headers = new Headers();
  headers.append("Authorization", "Bearer " + accessToken);
  headers.append("Content-type", "application/json");

  if (options?.method?.toUpperCase() !== "GET") {
    let antiforgeryToken = localStorage["XSRF-TOKEN"];
    headers.append("X-XSRF-TOKEN", antiforgeryToken);
  }

  let fetchOptions = {
    method: "GET",
    headers,
    credentials: endpoint.startsWith(radarApiUrl) ? "include" : undefined,
    ...options,
  };

  let promise: Promise<Response> = new Promise((resolve, reject) => {
    fetch(endpoint, fetchOptions)
      .then((response) => {
        if (response.ok && (settings?.doNotErrorOnNoContent || response.status !== 204)) {
          let antiforgeryToken = getCookie("XSRF-TOKEN");
          if (antiforgeryToken) {
            localStorage["XSRF-TOKEN"] = antiforgeryToken;
          }

          resolve(response);
        } else {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") >= 0) {
            response.json().then((error) => reject(error));
          } else {
            response.text().then((errorText) => {
              const statusCode = response.status.toString(),
                error: IApiError = {
                  statusCode,
                  message: response.statusText || getErrorMessage(statusCode, errorText),
                };
              reject(error);
            });
          }
        }
      })
      .catch((error) => reject(error));
  });

  switch (dataType) {
    case ResponseDataType.json:
      return promise.then((response) => (response.status === 204 ? {} : response.json()));
    case ResponseDataType.blob:
      return promise.then((response) => response.blob());
    case ResponseDataType.text:
      return promise.then((response) => response.text());
    case ResponseDataType.file:
      return promise.then((response) => {
        var contentDisposition = response.headers.get("content-disposition"),
          filename = "download-file.txt";

        if (contentDisposition) {
          var filenameStartIndex = contentDisposition.indexOf("filename=") + 9,
            filenameEndIndex = contentDisposition.indexOf(";", filenameStartIndex);

          filename = contentDisposition.substring(filenameStartIndex, filenameEndIndex);
        }

        return response
          .blob()
          .then((blob) => URL.createObjectURL(blob))
          .then((url) => {
            var a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return url;
          });
      });
    default:
      return promise;
  }
};

export interface IApiError {
  statusCode: string;
  message: string;
  stackTrace?: string;
}

export const getErrorMessage = (statusCode: string, errorMessage?: string): string => {
  let msg;

  if (errorMessage) {
    msg = errorMessage;
  } else {
    switch (statusCode) {
      case "204":
        msg = noDataErrorMsg;
        break;
      case "401":
        msg = noPermissionErrorMsg;
        break;
      default:
        msg = `Status code ${statusCode} is returned from the API.`;
        break;
    }
  }

  return msg;
};

export const errorHandler = (error: IApiError, reject: Function, messageTitle: string) =>
  reject(`${messageTitle} \n\n${error && error.message}`);

export const getQueryString = (refreshData: boolean, isStaging: boolean = false): string => {
  let queryString = refreshData || isStaging ? "?" : "";

  refreshData && (queryString += refreshQueryStringKey);

  isStaging && (queryString += "&" + stagingQueryStringKey);

  return queryString;
};

export const getQueryStringWithIdType = (
  refreshData: boolean,
  isStaging: boolean = false,
  idType: string = ""
): string => {
  let queryString = refreshData || isStaging || idType ? "?" : "";

  refreshData && (queryString += refreshQueryStringKey);

  isStaging && (queryString += "&" + stagingQueryStringKey);

  idType && (queryString += `&idType=${idType}`);

  return queryString;
};

export const isGlobalEnvironment = (environment: string) => {
  let env = environment?.toLowerCase();
  return !env || env === "prod" || env === "int" || env === "dev";
};
